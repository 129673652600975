import moment from 'moment';
import { f7 } from "framework7-vue";
import twzipcode from "twzipcode-data";
import _ from 'lodash';
import { computed, reactive } from "vue";

const { counties, zipcodes } = twzipcode('zh-tw');

class Random {
    /**
     * 生成亂數
     * @param {Number} min
     * @param {Number} max
     * @returns
     */
    randomNum(min, max) {
        min = Number(min);
        max = Number(max);

        return Math.floor(Math.random() * (max - min) + min);
    }

    /**
     * 生成隨機顏色
     * @param {Number} min
     * @param {Number} max
     * @returns
     */
    randomColor(min, max) {
        let r = this.randomNum(min, max);
        let g = this.randomNum(min, max);
        let b = this.randomNum(min, max);

        return `rgb(${r}, ${g}, ${b})`;
    }
}

const MukiUtil = {
    // 是否是jQuery 物件
    isjQueryObject: function (target) {
        return target instanceof jQuery
    },
    toNumber: function (data) {
        if ('number' === typeof data) {
            return data
        }
        if ('string' === typeof data) {
            var n = parseInt(data.trim().replaceAll(',', ''));
            return n;
        }
    },
    uuid: function () {
        var d = Date.now();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
}

class Str {
    /**
     * 將字串轉成陣列
     * @param {String} string 要轉成陣列的字串
     * @param {String} splitWay 拆陣列的方式
     * @returns
     */
    splitString2Arr(string, splitWay) {
        return string.split(splitWay);
    }

    /**
     * 符合判斷條件的值轉成預設值
     * @param {*} value 要被判斷的值
     * @param {*Array} conditionArr 判斷條件集合
     * @param {*} defaultVal 預設值
     * @returns
     */
    judgedVal2EmptyStr(value, conditionArr, defaultVal) {
        const condition = conditionArr.some((val) => value === val);

        if (condition) {
            return defaultVal || "";
        }

        return value;
    }

    /**
     * 字串陣列相接
     * @param {Array} strArr 字串陣列
     * @param {Function} callback
     * @returns
     */
    arrJoin2Str(strArr, callback) {
        if (callback) {
            return strArr.map((str) => callback(str)).join("");
        }

        return strArr.join("");
    }
}

class View {
    /**
     * 取得tag外部高
     * @param { Object } el DOM 
     * @returns tag外部高
     */
    outerHeight(el) {
        const offsetHeight = el.offsetHeight;
        const marginTop = el.style.marginTop;
        const marginBottom = el.style.marginBottom;
        const outerHeight = offsetHeight + marginTop + marginBottom;

        return outerHeight;
    }

    autoFillHeight(tagOfArr, numOfArr) {
        let minusHeight = 0;

        if (tagOfArr) {
            tagOfArr.forEach((tag, key) => {
                minusHeight += Number(this.outerHeight(tag));
            })
        }
        
        if (numOfArr) {
            numOfArr.forEach((num, key) => {
                minusHeight += num;
            })
        }

        return `calc(100vh - ${minusHeight}px)`;
    }
}

// css class 處理
const stringArrToClass = function(sty){
    const computeStyArr = computed(() => {
        var newArr = [];
        sty.split(' ').forEach((sty, idx) => {
            if (sty) {
                newArr.push(sty)
            }
        })
    
        return newArr;
    })
    
    const computeStyStr = computed(() => {
        var arr = computeStyArr.value.map((sty, idx) => {
            return 'sty-' + sty
        })
        return arr.join(' ')
    })

    return {
        computeStyArr,
        computeStyStr
    }
}


// 月份
function get_year_arr(year_from, year_to) {
    var year_arr = [];
    var year_from = parseInt(year_from);
    var year_to = parseInt(year_to);
    var current_year = year_from;
    var length = Math.abs(year_from - year_to) + 1

    if (length == 1) {
        return [current_year]
    }

    while (year_arr.length != length) {
        year_arr.push(current_year);

        if (year_from > year_to) {
            current_year--
        } else {
            current_year++
        }
    }

    for (var year = year_from; year <= 12; year++) {
        if (zero_prefix && year < 10) {
            year_arr.push('0' + String(year));
        } else {
            year_arr.push(String(year));
        }
    }

    return year_arr;
}
const GetYearArr = get_year_arr;

// 月份
function get_month_arr(zero_prefix) {
    var zero_prefix = zero_prefix == false ? false : true;
    var month_arr = [];

    for (var month = 1; month <= 12; month++) {
        if (zero_prefix && month < 10) {
            month_arr.push('0' + String(month));
        } else {
            month_arr.push(String(month));
        }
    }

    return month_arr;
}
const GetMonthArr = get_month_arr;

// 日
function get_date_arr(zero_prefix) {
    var zero_prefix = zero_prefix == false ? false : true;
    var date_arr = [];

    for (var date = 1; date <= 31; date++) {
        if (zero_prefix && date < 10) {
            date_arr.push('0' + String(date));
        } else {
            date_arr.push(String(date));
        }
    }

    return date_arr;
}
const GetDateArr = get_date_arr;

// 計數器
function Counter(options) {
    var timer;
    var instance = this;
    var seconds = options.seconds || 10;
    var onUpdateStatus = options.onUpdateStatus || function () { };
    var onCounterEnd = options.onCounterEnd || function () { };
    var onCounterStart = options.onCounterStart || function () { };

    function decrementCounter() {
        onUpdateStatus(seconds);
        if (seconds === 0) {
            stopCounter();
            onCounterEnd();
            return;
        }
        seconds--;
    };

    function startCounter() {
        onCounterStart();
        clearInterval(timer);
        timer = 0;
        decrementCounter();
        timer = setInterval(decrementCounter, 1000);
    };

    function stopCounter() {
        clearInterval(timer);
    };

    return {
        start: function () {
            startCounter();
        },
        stop: function () {
            stopCounter();
        }
    }
};

const RandomFunc = new Random();
const StrFunc = new Str();
const ViewFunc = new View();

// ==== Picker ====
// 觸發input 更新
function _triggerPickerInput(ele){
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent("input", false, true);
    ele.dispatchEvent(evt);
}
// 基礎picker
function BasePicker(setting) {
    this.picker = {};
    this.defaultSetting = {
        formatValue: function (values, displayValues) {
            return displayValues
        },
    }
    this.new_setting = $.extend(true, {}, this.defaultSetting, setting);

    this.formel = setting.inputEl;
    this.create();
    this.creater_func = this.constructor;

    Object.defineProperty(this, "value", {
        get: function () {
            return this.picker.value;
        },
    });
    Object.defineProperty(this, "displayValue", {
        get: function () {
            return this.picker.displayValue;
        },
    });
}
BasePicker.prototype.create = function () {
    if (!$(this.formel).is('.picker-inited')) {

        $(this.formel).addClass('picker-inited');

        // PRIVATE ONBEFORECREATE
        this._onBeforeCreate();
        this.onBeforeCreate();

        // CREATE PICKER
        this.picker = f7.picker.create(this.new_setting);

        // BIND EVENT MUST BE RIGHT AFTER CREATE !
        this.bindEventListener();
        this._bindExtendBuildinEventListener();
        $(this.formel).data('picker', this.picker);

        this.onAfterCreate();
        this._setOriginal();
        this._onAfterCreate();
    }
}
BasePicker.prototype.veeValidateSupport = function () {
    var that = this;
    var orgFormateValueFunc = this.new_setting.formatValue;
    this.new_setting.formatValue = function (values, displayValues) {
        values.forEach((val, idx) => {
            that.picker.$inputEl[idx].value = displayValues
            _triggerPickerInput(that.picker.$inputEl[idx], 'change');
        })
        orgFormateValueFunc()
    }
}
BasePicker.prototype.bindEventListener = function () {

}
BasePicker.prototype._setOriginal = function () {
    // 支援 form_data_change_check()
    var data_original = $(this.formel).attr('data-original');
    if (!data_original) {
        // 若沒設定 則在這裡加上 data-original
        if (this.formel) {
            $(this.formel).attr('data-original', this.formel.value)
        }
        $(this.formel).removeClass('is-data-changed')
    }
}
BasePicker.prototype.recreatePicker = function () {
}
BasePicker.prototype._onBeforeCreate = function () {
    this.veeValidateSupport()
}
BasePicker.prototype.onBeforeCreate = function () {

}
BasePicker.prototype.onAfterCreate = function () {
}
BasePicker.prototype._onAfterCreate = function () {
    var that = this;
    this.destroy = function () {
        that.picker.destroy();
    };
    this.open = function () {
        that.picker.open();
    };
    this.close = function () {
        that.picker.close();
    };
    this.setValue = function (values, duration) {
        that.picker.setValue(values, duration);
    };
    this.getValue = function () {
        that.picker.setValue();
    };
    this.addValue = function () {
        that.picker.addValue();
    };
    this.on = function (event, handler) {
        that.picker.on(event, handler);
    };
    this.once = function (event, handler) {
        that.picker.once(event, handler);
    };
    this.off = function (event) {
        that.picker.off(event);
    };
    this.emit = function (event, value, displayValue) {
        that.picker.emit(event, value, displayValue);
    };
}
BasePicker.prototype._bindExtendBuildinEventListener = function () {
    // 擴充內建的事件監聽，確保cllback發生時，客製picker運算已完成
    var that = this;
    var picker = this.picker;
    var eventListeners = picker.eventsListeners
    this.picker.on('change', function (picker, value, displayValues) {
        if (eventListeners.mukiChange) {
            eventListeners.mukiChange[0]()
        }
    });
    this.picker.on('init', function (picker) {
        if (eventListeners.mukiInit) {
            eventListeners.mukiInit[0](picker)
        }
    });
    this.picker.on('open', function (picker) {
        if (eventListeners.mukiOpen) {
            eventListeners.mukiOpen[0](picker)
        }
    });
    this.picker.on('opened', function (picker) {
        if (eventListeners.mukiOpened) {
            eventListeners.mukiOpened[0](picker)
        }
    });
    this.picker.on('close', function (picker) {
        if (eventListeners.mukiClose) {
            eventListeners.mukiClose[0](picker)
        }
    });
    this.picker.on('closed', function (picker) {
        if (eventListeners.mukiClosed) {
            eventListeners.mukiClosed[0](picker)
        }
    });
    this.picker.on('beforeDestory', function (picker) {
        if (eventListeners.mukiBeforeDestory) {
            eventListeners.mukiBeforeDestory[0](picker)
        }
    });
}
BasePicker.prototype.recreatePicker = function (setting) {
    var picker = this.picker;
    var params = picker.params;
    var ipt_field = picker.inputEl;
    var currentValue = picker.value;
    var recreate_setting = $.extend(true, {}, { input: ipt_field }, params, setting);

    // clean
    $(ipt_field).removeClass('picker-inited')
    picker.destroy();
    ipt_field.value = '';
    // delete recreate_setting.cols;

    var new_picker = new this.constructor(recreate_setting)

    // 回傳 recreate
    return new_picker;
}

function Date_picker(setting) {
    var that = this;
    var _default = {
        // Custom Setting
        custom: {
            // 顯示欄位
            year: true,
            month: true,
            date: true,
            // 後綴
            postfix: '',
            // 年分開始
            yearBase: (new Date()).getFullYear() + 25,
            // 年份顯示幾年
            yearLength: 146,
            // 向上計算、向下計算 down : yearBase 往下減/ up : yearBase 往上加
            yearDirection: 'down',
            // 顯示 星期幾
            showWeekDay: false,
            // 星期幾顯示格式
            weekDayFormat: 'short', // short | long | function(weekday)
            // 開放選擇時間 - 12小時制
            selectTime12: false,
            // 日期分隔顯示
            dateDivider: '-',
            // 月份 0 前綴
            monthZeroPrefix: true,
            // 月份 0 前綴
            minuteZeroPrefix: true,
            // 分鐘間隔
            selectTimeMinutesInterval: 1,

            // 禁止日期 [[2020,11,23],[2020,12,30]]
            disabled: [],

            // 預設日期
            defaultDate: null,
            // [(new Date()).getFullYear(), (new Date()).getMonth() + 1, (new Date()).getDate(), '上午', '9', '00']

            // 第一次打開時的預設
            defaultDateFirstOpen: [(new Date()).getFullYear(), (new Date()).getMonth() + 1, (new Date()).getDate(), '上午', '9', '00'],

            // 日期限制
            maxDate: [],
            minDate: [],
        },
        // data
        data: {
            // 目前使用者操作的 col 代替f7沒有提共的 event.target
            current_col: '',
        },

        // F7 Picker Setting
        inputEl: setting.input,
        rotateEffect: true,
        renderToolbar: function () {
            return common.picker_toolbar
        },
        cols: [
            // Years
            {
                name: 'year',
                textAlign: 'left',
                values: ('2020 2019 2018 2017 2016 2015').split(' '),
            },
            {
                name: 'y-m-divider',
                divider: true,
                content: '/',
            },
            // Months
            {
                name: 'month',
                textAlign: 'center',
                values: ('01 02 03 04 05 06 07 08 09 10 11 12').split(' '),
                // onChange: function(picker ,value) {
                //     console.log('month', value)
                // }
            },
            {
                name: 'm-d-divider',
                divider: true,
                content: '/',
            },
            // Days
            {
                name: 'date',
                textAlign: 'center',
                values: ('01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31').split(' '),
            },
        ],
        formatValue: function (values, displayValues) {
            var new_setting = that.new_setting;
            var custom_setting = that.new_setting.custom;

            var formated = ''
            var date_divider = new_setting.custom.dateDivider;
            var year = displayValues[0] || '';
            var y_m_divider = date_divider; // 分隔線
            var month = displayValues[1] || '';
            var m_d_divider = date_divider; // 分隔線
            var date = displayValues[2] || '';
            var ampm = displayValues[3] || '';
            var hour = displayValues[4] || '';
            var minute = displayValues[5] || '';

            var day_ref = {
                0: '日',
                1: '一',
                2: '二',
                3: '三',
                4: '四',
                5: '五',
                6: '六',
            }

            if (year && month && date) {
                var weekday = day_ref[(new Date(year + '-' + month + '-' + date)).getDay()];
            }

            if (new_setting.custom) {
                var custom = new_setting.custom;

                // 依欄位是否顯示 做相關調整
                if (!custom.year || !custom.month) y_m_divider = ''
                if (!custom.date) m_d_divider = ''

                // 組成 displayValues
                formated = year + y_m_divider + month + m_d_divider + date;

                // 12 時制
                if (custom.selectTime12) {
                    // 有顯示到時間才加 :
                    if (values.length == 6) {
                        formated += ' ' + ampm + hour + ':' + minute
                    }
                }

                // 日期顯示
                if (custom.showWeekDay) {
                    if (typeof custom.weekDayFormat === 'function') {
                        // 自訂 weekday 顯示
                        formated = formated + custom.WeekDayFormat(weekday)

                    } else if (custom.weekDayFormat === 'long') {

                        formated = formated + ' 星期' + weekday;
                    } else {

                        formated = formated + ' (' + weekday + ') ';
                    }
                }

                // 後綴
                if (custom.postfix) {
                    formated = formated + custom.postfix;
                };
            }

            return formated;
        },
        on: {
            init: function (picker, values, displayValues) {
            },
            change: function (picker, values, displayValues) {

            },
        }
    }

    BasePicker.call(this, $.extend(true, {}, _default, setting));

    this.defaultSetting = _default;
}
Date_picker.prototype = Object.create(BasePicker.prototype);
Date_picker.prototype.bindEventListener = function () {
    var picker = this.picker;
    var new_setting = this.new_setting;
    var custom_setting = this.new_setting.custom;

    picker.on('change', function (picker, value, displayValue) {
        var input = $(picker.$inputEl);

        var daysInMonth = new Date(picker.value[0], picker.value[1], 0).getDate();
        if (value[2] > daysInMonth) {
            picker.cols[4].setValue(daysInMonth);
        }

        // 儲存年月資料
        var year = '', month = '', date = '', hours = '', minutes = '', ampm = '';

        year = picker.value[0];
        month = picker.value[1];
        date = picker.value[2];

        // 更新 data-xxx
        if (year && new_setting.custom.year) input.attr('data-year', year);
        if (month && new_setting.custom.month) input.attr('data-month', month);
        if (date && new_setting.custom.date) input.attr('data-date', date);

        if (new_setting.custom.selectTime12) {

            ampm = picker.value[3];
            hours = picker.value[4];
            minutes = picker.value[5];

            // 上午下午 針對 0 點 12點做調整
            if (picker.cols.length > 0) {
                var col_ampm = picker.cols[5];
                var col_hours = picker.cols[6];

                // 使用者操作 小時
                if (new_setting.data.current_col.name == 'hours') {
                    // 上午的hour沒有12
                    if (ampm == "上午" && parseInt(hours) == 12) {
                        ampm = '下午'
                        col_ampm.setValue(ampm);
                    }
                    // 下午的hour沒有0:00
                    if (ampm == "下午" && parseInt(hours) == 0) {
                        ampm = '上午'
                        col_ampm.setValue(ampm);
                    }
                } else if (new_setting.data.current_col.name == 'ampm') {
                    // 使用者操作 ampm

                    // 修改 hours 為 11
                    if (ampm == "上午" && parseInt(hours) == 12) {
                        hours = '11'
                        col_hours.setValue(hours);
                    }
                    // 修改 hours 為1
                    if (ampm == "下午" && parseInt(hours) == 0) {
                        hours = '1'
                        col_hours.setValue(hours);
                    }

                }
            }

            input.attr('data-ampm', ampm);
            input.attr('data-hours', hours);
            input.attr('data-minutes', minutes);
        }

        // 顯示 星期幾
        if (custom_setting.showWeekDay) {
            // 更新星期幾
            var day_fef = {
                0: '日',
                1: '一',
                2: '二',
                3: '三',
                4: '四',
                5: '五',
                6: '六',
            }

            picker_weekday = picker.$inputEl[3];

            var weekday = day_fef[(new Date(values[0] + '-' + values[1] + '-' + values[2])).getDay()];

            if (weekday && picker.cols[5]) {
                picker.cols[5].replaceValues([weekday]);
            }
        }

        var date_info = moment(year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ' ' + ampm, 'YYYYMMDD HH:mm a');

        input.attr('data-time', date_info._d.getTime())
    })
}
Date_picker.prototype.onBeforeCreate = function () {
    var picker = this.picker;
    var new_setting = this.new_setting;
    var custom_setting = this.new_setting.custom;

    // 年分
    if (new_setting.custom.year) {
        var year_arr = [];
        if (new_setting.custom.yearDirection == 'down') {

            // 年分向下
            var year_from = new_setting.custom.yearBase;
            var year_to = new_setting.custom.yearBase - new_setting.custom.yearLength;
            for (var year = new_setting.custom.yearBase; year > year_to; year--) {
                year_arr.push(String(year));
            }

            new_setting.cols[0].values = year_arr;
        } else {
            // 年分向上
            var year_from = new_setting.custom.yearBase;
            var year_to = new_setting.custom.yearBase + new_setting.custom.yearLength;
            for (var year = new_setting.custom.yearBase; year < year_to; year++) {
                year_arr.push(String(year));
            }

            new_setting.cols[0].values = year_arr;
        }
    } else {

        new_setting.cols.forEach(function (col, idx) {
            if (col.name == 'year') {
                new_setting.cols.splice(idx, 1)
            }
        });
    }

    // 月份
    if (new_setting.custom.month) {
        new_setting.cols[2].values = get_month_arr();
    } else {
        new_setting.cols.forEach(function (col, idx) {
            if (col.name == 'y-m-divider') {
                new_setting.cols.splice(idx, 1)
            }
        });
        new_setting.cols.forEach(function (col, idx) {
            if (col.name == 'month') {
                new_setting.cols.splice(idx, 1)
            }
        });
    }

    // 日
    if (new_setting.custom.date) {

    } else {
        new_setting.cols.forEach(function (col, idx) {
            if (col.name == 'date') {
                new_setting.cols.splice(idx, 1)
            }
        });

        new_setting.cols.forEach(function (col, idx) {
            if (col.name == 'm-d-divider') {
                new_setting.cols.splice(idx, 1)
            }
        });
    }

    // 自訂設定處理
    if (custom_setting) {

        // 顯示 星期幾
        if (custom_setting.showWeekDay) {
            new_setting.cols.push({
                textAlign: 'center',
                values: ('一').split(' ')
            })
        }

        //  選擇 12時制 時間
        if (custom_setting.selectTime12) {
            new_setting.cssClass = "sty-selectTime12";

            // AM PM
            new_setting.cols.push({
                textAlign: 'center',
                values: ('上午 下午').split(' '),
                onChange: function (picker, ampm, displayHours) {
                    new_setting.data.current_col = {
                        name: 'ampm',
                    };

                    var hours_col = picker.cols[6];
                    var hours_col_val = hours_col && hours_col.value;

                    if (hours_col) {
                        if (ampm == '上午') {
                            picker_col_replace_val(hours_col, ('0 1 2 3 4 5 6 7 8 9 10 11').split(' '))
                        } else {
                            picker_col_replace_val(hours_col, ('1 2 3 4 5 6 7 8 9 10 11 12').split(' '))
                        }
                        picker_col_set_val(hours_col, hours_col_val)
                    }
                }
            });

            // HOURS
            let hours_values = [];
            if (
                (!new_setting.custom.defaultDate ||
                    new_setting.custom.defaultDate[5] !== '下午') &&
                (!new_setting.custom.defaultDateFirstOpen ||
                    new_setting.custom.defaultDateFirstOpen[5] !== '下午')
            ) {
                // 上午
                hours_values = ('0 2 3 4 5 6 7 8 9 10 11').split(' ')
            } else {
                // 下午
                hours_values = ('1 2 3 4 5 6 7 8 9 10 11 12').split(' ')
            }

            new_setting.cols.push({
                textAlign: 'center',
                cssClass: 'col-hours',
                values: hours_values,
                onChange: function (picker, ampm, displayHours) {
                    new_setting.data.current_col = {
                        name: 'hours',
                    };
                }
            });

            new_setting.cols.push({
                divider: true,
                content: ':'
            });

            // MINUTES
            var mins_arr = [];
            for (var i = 0; i < 60; i += new_setting.custom.selectTimeMinutesInterval) {
                if (i < 10) {
                    mins_arr.push('0' + String(i))
                } else {
                    mins_arr.push(i)
                }
            }

            new_setting.cols.push({
                textAlign: 'center',
                values: mins_arr
            });

        }


        // 紀錄原資料
        new_setting.data.original_cols = $.extend(true, {}, new_setting.cols);


        // 日期限制
        if (new_setting.custom.maxDate || new_setting.custom.minDate) {
            // 目前支援的格式為 new_setting至少要是 [yyy, /, mm,/ ,dd]
            if (new_setting.cols.length >= 5) {
                // max
                let max_date = new_setting.custom.maxDate || [];

                let today = moment([(new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate()])
                let tomorrow = today.clone().add(1, 'days')
                let yesterday = today.clone().subtract(1, 'days')

                today = [today.format('YYYY'), today.format('MM'), today.format('DD')];
                tomorrow = [tomorrow.format('YYYY'), tomorrow.format('MM'), tomorrow.format('DD')]
                yesterday = [yesterday.format('YYYY'), yesterday.format('MM'), yesterday.format('DD')]

                if (max_date == 'today') {
                    max_date = today
                }

                if (max_date == 'tomorrow') {
                    max_date = tomorrow
                }

                if (max_date == 'yesterday') {
                    max_date = yesterday
                }

                let max_year = parseInt(max_date[0]);
                let max_month = parseInt(max_date[1]);
                let max_day = parseInt(max_date[2]);

                // min
                let min_date = new_setting.custom.minDate || [];

                if (min_date == 'today') {
                    min_date = today
                }

                if (min_date == 'tomorrow') {
                    min_date = tomorrow
                }

                if (min_date == 'yesterday') {
                    min_date = yesterday
                }

                let min_year = parseInt(min_date[0]);
                let min_month = parseInt(min_date[1]);
                let min_day = parseInt(min_date[2]);

                // new
                let new_year_arr = new_setting.cols[0].values;
                let new_month_arr = new_setting.cols[2].values;
                let new_day_arr = new_setting.cols[4].values;

                // current
                let current_year = parseInt(custom_setting.defaultDate ? custom_setting.defaultDate[0] : custom_setting.defaultDateFirstOpen[0]);
                let current_month = parseInt(custom_setting.defaultDate ? custom_setting.defaultDate[1] : custom_setting.defaultDateFirstOpen[1]);
                let current_day = parseInt(custom_setting.defaultDate ? custom_setting.defaultDate[2] : custom_setting.defaultDateFirstOpen[2]);

                function filter_year(old_arrr, func) {
                    new_setting.cols[0].values.filter(function (value, idx) {
                        return func(value, idx);
                    });
                }


                // 年
                if (max_year) {
                    new_year_arr = new_year_arr.filter(function (value, idx) {
                        return value <= max_year;
                    });
                }
                if (min_year) {
                    new_year_arr = new_year_arr.filter(function (value, idx) {
                        return value >= min_year;
                    });
                }

                new_setting.cols[0].values = new_year_arr;

                function min_max_year_change(picker, year, init) {
                    // 優先使用傳入值
                    var _current_year = year;
                    // 上層數值更新全域
                    current_year = year;
                    // 上層優先使用 picker col 紀錄值
                    var _current_month = (picker.value && picker.value[1]) || current_month;
                    // 上層優先使用 picker col 紀錄值
                    var _current_day = (picker.value && picker.value[2]) || current_day;

                    // max
                    if (parseInt(_current_year) == parseInt(max_year)) {
                        if (max_month) {
                            new_month_arr = new_month_arr.filter(function (value, idx) {
                                return value <= max_month;
                            });
                        }
                    }

                    // min
                    if (parseInt(_current_year) == parseInt(min_year)) {
                        if (min_month) {
                            new_month_arr = new_month_arr.filter(function (value, idx) {
                                return value >= min_month;
                            });
                        }
                    }


                    if (init) {
                        new_setting.cols[2].values = new_month_arr;
                    } else {
                        picker_col_replace_val(picker.cols[2], new_month_arr);

                        if (new_month_arr.indexOf(_current_month) == -1) {
                            // 目標月份找不到時 用最接近月份
                            // 避免出現月份不對(避免month的資料為上一年的) 造成天的限制沒有設定到
                            _current_month = closest_num(new_month_arr, _current_month)
                        }

                        picker_col_set_val(picker.cols[2], _current_month);
                        picker_col_set_val(picker.cols[4], _current_day);
                    }

                    // 年change時 也要觸發 month change 。 避免month的資料為上一年的
                    // min_max_month_change(picker, _current_month, init);

                    // 預設值復原
                    new_month_arr = new_setting.data.original_cols[2].values

                }

                min_max_year_change(picker, current_year, true);

                new_setting.cols[0].onChange = function (picker, year) {
                    min_max_year_change(picker, year)
                }

                // 月
                function min_max_month_change(picker, month, init) {
                    // 年使用全域目前的值
                    current_year = current_year;
                    // 優先使用傳入值
                    current_month = month || current_month;
                    // 使用 picker.col 值
                    current_day = (picker.value && picker.value[2]) || current_day;

                    // reset first
                    var new_day_arr = new_setting.data.original_cols[4].values.slice();


                    // max
                    if (parseInt(current_year) == parseInt(max_year)) {
                        if (parseInt(current_month) == parseInt(max_month)) {
                            if (max_day) {
                                new_day_arr = new_day_arr.filter(function (value, idx) {
                                    return value <= max_day;
                                });
                            }
                        }
                    }

                    // min
                    if (parseInt(current_year) == parseInt(min_year)) {
                        if (parseInt(current_month) == parseInt(min_month)) {
                            if (min_day) {
                                new_day_arr = new_day_arr.filter(function (value, idx) {
                                    return value >= min_day;
                                });
                            }

                        }
                    }


                    // 處理 disabled 的日期
                    if (new_setting.custom.disabled) {

                        new_setting.custom.disabled.forEach(function (date, idx) {
                            if (parseInt(current_year) == parseInt(date[0])) {
                                if (parseInt(current_month) == parseInt(date[1])) {
                                    new_day_arr = new_day_arr.filter(function (value, idx) {
                                        return parseInt(value) !== parseInt(date[2]);
                                    });
                                }
                            }
                        });
                    }


                    if (init) {
                        new_setting.cols[4].values = new_day_arr;
                    } else {
                        picker_col_replace_val(picker.cols[4], new_day_arr)
                        picker_col_set_val(picker.cols[4], current_day)
                    }

                }

                min_max_month_change(picker, current_month, true);

                new_setting.cols[2].onChange = function (picker, month) {
                    min_max_month_change(picker, month)
                }
            }
        }
    }

    // 支援原生 value 預設值
    if (new_setting.value) {
        new_setting.custom.defaultDate = new_setting.value;
        delete new_setting.value;
    }
}
Date_picker.prototype.onAfterCreate = function () {
    var new_setting = this.new_setting;
    var custom_setting = this.new_setting.custom_setting;
    var picker = this.picker;

    // 預設日期
    if (picker.inputEl) {
        var default_val = moment(picker.inputEl.value);

        // 若為 不合格日期 嘗試轉成數值
        if (!default_val._isValid) {
            default_val = moment(parseInt(picker.inputEl.value), 'YYYY');
        }

        var default_y = default_val._d.getFullYear();
        var default_m = default_val._d.getMonth() + 1;
        var default_d = default_val._d.getDate();

        if (default_y && default_m && default_d) {
            new_setting.custom.defaultDate = [
                default_y, default_m, default_d, '上午', '9', '00'
            ]
        }
    }

    if (picker && new_setting.custom.defaultDate) {

        // 預設值月份加上 0 prefix
        if (new_setting.custom.monthZeroPrefix && parseInt(new_setting.custom.defaultDate[1]) < 10) {
            new_setting.custom.defaultDate[1] = '0' + parseInt(new_setting.custom.defaultDate[1])
        }

        // 日加上 zeroprefix
        if (parseInt(new_setting.custom.defaultDate[2]) < 10) {
            new_setting.custom.defaultDate[2] = '0' + parseInt(new_setting.custom.defaultDate[2])
        }

        // 小時處理
        if (new_setting.custom.defaultDate[4] && parseInt(new_setting.custom.defaultDate[4]) < 10) {
            new_setting.custom.defaultDate[4] = String(parseInt(new_setting.custom.defaultDate[4]))
        }

        // 分鐘處理
        if (new_setting.custom.defaultDate[5] && parseInt(new_setting.custom.defaultDate[5]) < 10) {
            new_setting.custom.defaultDate[5] = '0' + parseInt(new_setting.custom.defaultDate[5])
        }

        picker.setValue(new_setting.custom.defaultDate);
    }

    // 第一次打開的預設
    if (picker && new_setting.custom.defaultDateFirstOpen && !new_setting.custom.defaultDate && !new_setting.data.is_first_opened) {
        // 預設值月份加上 0 prefix
        if (new_setting.custom.monthZeroPrefix && parseInt(new_setting.custom.defaultDateFirstOpen[1]) < 10) {
            new_setting.custom.defaultDateFirstOpen[1] = '0' + parseInt(new_setting.custom.defaultDateFirstOpen[1])
        }

        // 日加上 zeroprefix
        if (parseInt(new_setting.custom.defaultDateFirstOpen[2]) < 10) {
            new_setting.custom.defaultDateFirstOpen[2] = '0' + parseInt(new_setting.custom.defaultDateFirstOpen[2])
        }

        // 小時處理
        if (new_setting.custom.defaultDateFirstOpen[4] && parseInt(new_setting.custom.defaultDateFirstOpen[4]) < 10) {
            new_setting.custom.defaultDateFirstOpen[4] = String(parseInt(new_setting.custom.defaultDateFirstOpen[4]))
        }

        // 分鐘處理
        if (new_setting.custom.defaultDateFirstOpen[5] && parseInt(new_setting.custom.defaultDateFirstOpen[5]) < 10) {
            new_setting.custom.defaultDateFirstOpen[5] = '0' + parseInt(new_setting.custom.defaultDateFirstOpen[5])
        }

        // 使用 .on 避免預設function被覆寫
        picker.on('open', function () {
            if (new_setting.data.is_first_opened) return;
            // 有 data-original 代表已有設定預設值
            // 2021/05/28 註解，避免 recreatePicker 之後第一次打開不會設定值。導致年分變成陣列第一筆
            // if ( picker.$inputEl.attr('data-original') ) return;

            // 標註已經第一次開啟過
            new_setting.data.is_first_opened = true;
            picker.setValue(new_setting.custom.defaultDateFirstOpen);
        });

    }
}


// City Region Picker
function createCityRegionPicker(inputs, setting) {
    var city_refer_region = {
        基隆市: [
            "仁愛區",
            "信義區",
            "中正區",
            "中山區",
            "安樂區",
            "暖暖區",
            "七堵區",
        ],
        臺北市: [
            "中正區",
            "大同區",
            "中山區",
            "松山區",
            "大安區",
            "萬華區",
            "信義區",
            "士林區",
            "北投區",
            "內湖區",
            "南港區",
            "文山區",
        ],
        新北市: [
            "萬里區",
            "金山區",
            "板橋區",
            "汐止區",
            "深坑區",
            "石碇區",
            "瑞芳區",
            "平溪區",
            "雙溪區",
            "貢寮區",
            "新店區",
            "坪林區",
            "烏來區",
            "永和區",
            "中和區",
            "土城區",
            "三峽區",
            "樹林區",
            "鶯歌區",
            "三重區",
            "新莊區",
            "泰山區",
            "林口區",
            "蘆洲區",
            "五股區",
            "八里區",
            "淡水區",
            "三芝區",
            "石門區",
        ],
        宜蘭縣: [
            "宜蘭市",
            "頭城鎮",
            "礁溪鄉",
            "壯圍鄉",
            "員山鄉",
            "羅東鎮",
            "三星鄉",
            "大同鄉",
            "五結鄉",
            "冬山鄉",
            "蘇澳鎮",
            "南澳鄉",
            "釣魚臺列嶼",
        ],
        新竹市: ["東區", "北區", "香山區"],
        新竹縣: [
            "竹北市",
            "湖口鄉",
            "新豐鄉",
            "新埔鎮",
            "關西鎮",
            "芎林鄉",
            "寶山鄉",
            "竹東鎮",
            "五峰鄉",
            "橫山鄉",
            "尖石鄉",
            "北埔鄉",
            "峨嵋鄉",
        ],
        桃園市: [
            "中壢區",
            "平鎮區",
            "龍潭區",
            "楊梅區",
            "新屋區",
            "觀音區",
            "桃園區",
            "龜山區",
            "八德區",
            "大溪區",
            "復興區",
            "大園區",
            "蘆竹區",
        ],
        苗栗縣: [
            "竹南鎮",
            "頭份市",
            "三灣鄉",
            "南庄鄉",
            "獅潭鄉",
            "後龍鎮",
            "通霄鎮",
            "苑裡鎮",
            "苗栗市",
            "造橋鄉",
            "頭屋鄉",
            "公館鄉",
            "大湖鄉",
            "泰安鄉",
            "銅鑼鄉",
            "三義鄉",
            "西湖鄉",
            "卓蘭鎮",
        ],
        臺中市: [
            "中區",
            "東區",
            "南區",
            "西區",
            "北區",
            "北屯區",
            "西屯區",
            "南屯區",
            "太平區",
            "大里區",
            "霧峰區",
            "烏日區",
            "豐原區",
            "后里區",
            "石岡區",
            "東勢區",
            "和平區",
            "新社區",
            "潭子區",
            "大雅區",
            "神岡區",
            "大肚區",
            "沙鹿區",
            "龍井區",
            "梧棲區",
            "清水區",
            "大甲區",
            "外埔區",
            "大安區",
        ],
        彰化縣: [
            "彰化市",
            "芬園鄉",
            "花壇鄉",
            "秀水鄉",
            "鹿港鎮",
            "福興鄉",
            "線西鄉",
            "和美鎮",
            "伸港鄉",
            "員林市",
            "社頭鄉",
            "永靖鄉",
            "埔心鄉",
            "溪湖鎮",
            "大村鄉",
            "埔鹽鄉",
            "田中鎮",
            "北斗鎮",
            "田尾鄉",
            "埤頭鄉",
            "溪州鄉",
            "竹塘鄉",
            "二林鎮",
            "大城鄉",
            "芳苑鄉",
            "二水鄉",
        ],
        南投縣: [
            "南投市",
            "中寮鄉",
            "草屯鎮",
            "國姓鄉",
            "埔里鎮",
            "仁愛鄉",
            "名間鄉",
            "集集鎮",
            "水里鄉",
            "魚池鄉",
            "信義鄉",
            "竹山鎮",
            "鹿谷鄉",
        ],
        嘉義市: ["東區", "西區"],
        嘉義縣: [
            "番路鄉",
            "梅山鄉",
            "竹崎鄉",
            "阿里山",
            "中埔鄉",
            "大埔鄉",
            "水上鄉",
            "鹿草鄉",
            "太保市",
            "朴子市",
            "東石鄉",
            "六腳鄉",
            "新港鄉",
            "民雄鄉",
            "大林鎮",
            "溪口鄉",
            "義竹鄉",
            "布袋鎮",
        ],
        雲林縣: [
            "斗南鎮",
            "大埤鄉",
            "虎尾鎮",
            "土庫鎮",
            "褒忠鄉",
            "東勢鄉",
            "臺西鄉",
            "崙背鄉",
            "麥寮鄉",
            "斗六市",
            "林內鄉",
            "古坑鄉",
            "莿桐鄉",
            "西螺鎮",
            "二崙鄉",
            "北港鎮",
            "水林鄉",
            "口湖鄉",
            "四湖鄉",
            "元長鄉",
        ],
        臺南市: [
            "中西區",
            "東區",
            "南區",
            "北區",
            "安平區",
            "安南區",
            "永康區",
            "歸仁區",
            "新化區",
            "左鎮區",
            "玉井區",
            "楠西區",
            "南化區",
            "仁德區",
            "關廟區",
            "龍崎區",
            "官田區",
            "麻豆區",
            "佳里區",
            "西港區",
            "七股區",
            "將軍區",
            "學甲區",
            "北門區",
            "新營區",
            "後壁區",
            "白河區",
            "東山區",
            "六甲區",
            "下營區",
            "柳營區",
            "鹽水區",
            "善化區",
            "大內區",
            "山上區",
            "新市區",
            "安定區",
        ],
        高雄市: [
            "新興區",
            "前金區",
            "苓雅區",
            "鹽埕區",
            "鼓山區",
            "旗津區",
            "前鎮區",
            "三民區",
            "楠梓區",
            "小港區",
            "左營區",
            "仁武區",
            "大社區",
            "東沙群島",
            "南沙群島",
            "岡山區",
            "路竹區",
            "阿蓮區",
            "田寮區",
            "燕巢區",
            "橋頭區",
            "梓官區",
            "彌陀區",
            "永安區",
            "湖內區",
            "鳳山區",
            "大寮區",
            "林園區",
            "鳥松區",
            "大樹區",
            "旗山區",
            "美濃區",
            "六龜區",
            "內門區",
            "杉林區",
            "甲仙區",
            "桃源區",
            "那瑪夏區",
            "茂林區",
            "茄萣區",
        ],
        屏東縣: [
            "屏東市",
            "三地門鄉",
            "霧臺鄉",
            "瑪家鄉",
            "九如鄉",
            "里港鄉",
            "高樹鄉",
            "鹽埔鄉",
            "長治鄉",
            "麟洛鄉",
            "竹田鄉",
            "內埔鄉",
            "萬丹鄉",
            "潮州鎮",
            "泰武鄉",
            "來義鄉",
            "萬巒鄉",
            "崁頂鄉",
            "新埤鄉",
            "南州鄉",
            "林邊鄉",
            "東港鎮",
            "琉球鄉",
            "佳冬鄉",
            "新園鄉",
            "枋寮鄉",
            "枋山鄉",
            "春日鄉",
            "獅子鄉",
            "車城鄉",
            "牡丹鄉",
            "恆春鎮",
            "滿州鄉",
        ],
        臺東縣: [
            "臺東市",
            "綠島鄉",
            "蘭嶼鄉",
            "延平鄉",
            "卑南鄉",
            "鹿野鄉",
            "關山鎮",
            "海端鄉",
            "池上鄉",
            "東河鄉",
            "成功鎮",
            "長濱鄉",
            "太麻里鄉",
            "金峰鄉",
            "大武鄉",
            "達仁鄉",
        ],
        花蓮縣: [
            "花蓮市",
            "新城鄉",
            "秀林鄉",
            "吉安鄉",
            "壽豐鄉",
            "鳳林鎮",
            "光復鄉",
            "豐濱鄉",
            "瑞穗鄉",
            "萬榮鄉",
            "玉里鎮",
            "卓溪鄉",
            "富里鄉",
        ],
        金門縣: ["金沙鎮", "金湖鎮", "金寧鄉", "金城鎮", "烈嶼鄉", "烏坵鄉"],
        連江縣: ["南竿鄉", "北竿鄉", "莒光鄉", "東引鄉"],
        澎湖縣: ["馬公市", "西嶼鄉", "望安鄉", "七美鄉", "白沙鄉", "湖西鄉"],
    };

    // 作為 f7 create picker 繫結
    // var virtualipt = document.createElement('input');

    // picker
    var picker = {};

    // 客製設定
    var custom_setting = (setting && setting["custom"]) || {};

    var new_setting = $.extend(
        true,
        {},
        {
            // Custom Setting
            custom: {
                // 預設值
                // default_value: ['臺北市', '中正區']
                zipcodeEl: '.zipcode',
                cityEl: '.zipcode',
                areaEl: '.zipcode',
            },
            // F7 Picker Setting
            inputEl: "",
            rotateEffect: true,
            renderToolbar: function () {
                return (
                    '<div class="toolbar">' +
                    '<div class="toolbar-inner">' +
                    '<div class="left">' +
                    //'<a href="#" class="link toolbar-clear-link">清除</a>' +
                    "</div>" +
                    '<div class="right">' +
                    '<a href="#" class="link sheet-close popover-close">確定</a>' +
                    "</div>" +
                    "</div>" +
                    "</div>"
                );
            },
            formatValue: function (values, displayValues) {
                // return;
            },
            cols: [
                {
                    textAlign: "center",
                    values: [
                        "基隆市",
                        "臺北市",
                        "新北市",
                        "宜蘭縣",
                        "新竹市",
                        "新竹縣",
                        "桃園市",
                        "苗栗縣",
                        "臺中市",
                        "彰化縣",
                        "南投縣",
                        "嘉義市",
                        "嘉義縣",
                        "雲林縣",
                        "臺南市",
                        "高雄市",
                        "屏東縣",
                        "臺東縣",
                        "花蓮縣",
                        "金門縣",
                        "連江縣",
                        "澎湖縣",
                    ],
                    onChange: function (picker, value, displayValue) {
                        // 更新地區
                        if (picker.cols[1].replaceValues !== undefined) {
                            picker.cols[1].replaceValues(city_refer_region[value]);
                        }
                    },
                },
                {
                    textAlign: "center",
                    values: ["中正區", "大同區"],
                },
            ],
            on: {
                open: function (picker) {
                },
                change: function (picker, value, displayValue) {
                },
            },
        },
        setting
    );

    // 第一個預設值
    new_setting.cols[1].values =
        city_refer_region[new_setting.cols[0].values[0]];

    // 尚未建立 則建立
    if (!$(inputs).is(".picker-inited")) {
        $(inputs).addClass("picker-inited");
        picker = f7.picker.create($.extend(new_setting, { inputEl: inputs }));

        // 預設值
        if (picker.$inputEl) {
            let inputs = picker.$inputEl;
            let city = inputs[0] && inputs[0].value;
            let region = inputs[1] && inputs[1].value;

            if (city || region) {
                new_setting.custom.default_value = [city, region];
                picker.setValue(new_setting.custom.default_value);
            }
        }

        // 觸發開啟
        $(inputs).on('click', () => {
            picker.open();
        })

        // 事件綁定
        picker.on('open', function (picker) {
            picker.setValue([
                picker.$inputEl[0].value,
                picker.$inputEl[1].value,
            ]);
        })
        picker.on('change', function (picker, value, displayValue) {
            var reObj = _.find(zipcodes, {
                county: value[0],
                city: value[1]
            })

            if (reObj.id) {
                picker.$inputEl[0].value = reObj.id;
                _triggerPickerInput(picker.$inputEl[0]);
            }
            
            picker.$inputEl[1].value = value[0];
            picker.$inputEl[2].value = value[1];
            _triggerPickerInput(picker.$inputEl[1]);
            _triggerPickerInput(picker.$inputEl[2]);
        })

        // 回傳 instance
        return picker;
    }
}

function reactiveCityRegionPicker(domeEl, setting) {
    var city_refer_region = {
        基隆市: [
            "仁愛區",
            "信義區",
            "中正區",
            "中山區",
            "安樂區",
            "暖暖區",
            "七堵區",
        ],
        臺北市: [
            "中正區",
            "大同區",
            "中山區",
            "松山區",
            "大安區",
            "萬華區",
            "信義區",
            "士林區",
            "北投區",
            "內湖區",
            "南港區",
            "文山區",
        ],
        新北市: [
            "萬里區",
            "金山區",
            "板橋區",
            "汐止區",
            "深坑區",
            "石碇區",
            "瑞芳區",
            "平溪區",
            "雙溪區",
            "貢寮區",
            "新店區",
            "坪林區",
            "烏來區",
            "永和區",
            "中和區",
            "土城區",
            "三峽區",
            "樹林區",
            "鶯歌區",
            "三重區",
            "新莊區",
            "泰山區",
            "林口區",
            "蘆洲區",
            "五股區",
            "八里區",
            "淡水區",
            "三芝區",
            "石門區",
        ],
        宜蘭縣: [
            "宜蘭市",
            "頭城鎮",
            "礁溪鄉",
            "壯圍鄉",
            "員山鄉",
            "羅東鎮",
            "三星鄉",
            "大同鄉",
            "五結鄉",
            "冬山鄉",
            "蘇澳鎮",
            "南澳鄉",
            "釣魚臺列嶼",
        ],
        新竹市: ["東區", "北區", "香山區"],
        新竹縣: [
            "竹北市",
            "湖口鄉",
            "新豐鄉",
            "新埔鎮",
            "關西鎮",
            "芎林鄉",
            "寶山鄉",
            "竹東鎮",
            "五峰鄉",
            "橫山鄉",
            "尖石鄉",
            "北埔鄉",
            "峨嵋鄉",
        ],
        桃園市: [
            "中壢區",
            "平鎮區",
            "龍潭區",
            "楊梅區",
            "新屋區",
            "觀音區",
            "桃園區",
            "龜山區",
            "八德區",
            "大溪區",
            "復興區",
            "大園區",
            "蘆竹區",
        ],
        苗栗縣: [
            "竹南鎮",
            "頭份市",
            "三灣鄉",
            "南庄鄉",
            "獅潭鄉",
            "後龍鎮",
            "通霄鎮",
            "苑裡鎮",
            "苗栗市",
            "造橋鄉",
            "頭屋鄉",
            "公館鄉",
            "大湖鄉",
            "泰安鄉",
            "銅鑼鄉",
            "三義鄉",
            "西湖鄉",
            "卓蘭鎮",
        ],
        臺中市: [
            "中區",
            "東區",
            "南區",
            "西區",
            "北區",
            "北屯區",
            "西屯區",
            "南屯區",
            "太平區",
            "大里區",
            "霧峰區",
            "烏日區",
            "豐原區",
            "后里區",
            "石岡區",
            "東勢區",
            "和平區",
            "新社區",
            "潭子區",
            "大雅區",
            "神岡區",
            "大肚區",
            "沙鹿區",
            "龍井區",
            "梧棲區",
            "清水區",
            "大甲區",
            "外埔區",
            "大安區",
        ],
        彰化縣: [
            "彰化市",
            "芬園鄉",
            "花壇鄉",
            "秀水鄉",
            "鹿港鎮",
            "福興鄉",
            "線西鄉",
            "和美鎮",
            "伸港鄉",
            "員林市",
            "社頭鄉",
            "永靖鄉",
            "埔心鄉",
            "溪湖鎮",
            "大村鄉",
            "埔鹽鄉",
            "田中鎮",
            "北斗鎮",
            "田尾鄉",
            "埤頭鄉",
            "溪州鄉",
            "竹塘鄉",
            "二林鎮",
            "大城鄉",
            "芳苑鄉",
            "二水鄉",
        ],
        南投縣: [
            "南投市",
            "中寮鄉",
            "草屯鎮",
            "國姓鄉",
            "埔里鎮",
            "仁愛鄉",
            "名間鄉",
            "集集鎮",
            "水里鄉",
            "魚池鄉",
            "信義鄉",
            "竹山鎮",
            "鹿谷鄉",
        ],
        嘉義市: ["東區", "西區"],
        嘉義縣: [
            "番路鄉",
            "梅山鄉",
            "竹崎鄉",
            "阿里山",
            "中埔鄉",
            "大埔鄉",
            "水上鄉",
            "鹿草鄉",
            "太保市",
            "朴子市",
            "東石鄉",
            "六腳鄉",
            "新港鄉",
            "民雄鄉",
            "大林鎮",
            "溪口鄉",
            "義竹鄉",
            "布袋鎮",
        ],
        雲林縣: [
            "斗南鎮",
            "大埤鄉",
            "虎尾鎮",
            "土庫鎮",
            "褒忠鄉",
            "東勢鄉",
            "臺西鄉",
            "崙背鄉",
            "麥寮鄉",
            "斗六市",
            "林內鄉",
            "古坑鄉",
            "莿桐鄉",
            "西螺鎮",
            "二崙鄉",
            "北港鎮",
            "水林鄉",
            "口湖鄉",
            "四湖鄉",
            "元長鄉",
        ],
        臺南市: [
            "中西區",
            "東區",
            "南區",
            "北區",
            "安平區",
            "安南區",
            "永康區",
            "歸仁區",
            "新化區",
            "左鎮區",
            "玉井區",
            "楠西區",
            "南化區",
            "仁德區",
            "關廟區",
            "龍崎區",
            "官田區",
            "麻豆區",
            "佳里區",
            "西港區",
            "七股區",
            "將軍區",
            "學甲區",
            "北門區",
            "新營區",
            "後壁區",
            "白河區",
            "東山區",
            "六甲區",
            "下營區",
            "柳營區",
            "鹽水區",
            "善化區",
            "大內區",
            "山上區",
            "新市區",
            "安定區",
        ],
        高雄市: [
            "新興區",
            "前金區",
            "苓雅區",
            "鹽埕區",
            "鼓山區",
            "旗津區",
            "前鎮區",
            "三民區",
            "楠梓區",
            "小港區",
            "左營區",
            "仁武區",
            "大社區",
            "東沙群島",
            "南沙群島",
            "岡山區",
            "路竹區",
            "阿蓮區",
            "田寮區",
            "燕巢區",
            "橋頭區",
            "梓官區",
            "彌陀區",
            "永安區",
            "湖內區",
            "鳳山區",
            "大寮區",
            "林園區",
            "鳥松區",
            "大樹區",
            "旗山區",
            "美濃區",
            "六龜區",
            "內門區",
            "杉林區",
            "甲仙區",
            "桃源區",
            "那瑪夏區",
            "茂林區",
            "茄萣區",
        ],
        屏東縣: [
            "屏東市",
            "三地門鄉",
            "霧臺鄉",
            "瑪家鄉",
            "九如鄉",
            "里港鄉",
            "高樹鄉",
            "鹽埔鄉",
            "長治鄉",
            "麟洛鄉",
            "竹田鄉",
            "內埔鄉",
            "萬丹鄉",
            "潮州鎮",
            "泰武鄉",
            "來義鄉",
            "萬巒鄉",
            "崁頂鄉",
            "新埤鄉",
            "南州鄉",
            "林邊鄉",
            "東港鎮",
            "琉球鄉",
            "佳冬鄉",
            "新園鄉",
            "枋寮鄉",
            "枋山鄉",
            "春日鄉",
            "獅子鄉",
            "車城鄉",
            "牡丹鄉",
            "恆春鎮",
            "滿州鄉",
        ],
        臺東縣: [
            "臺東市",
            "綠島鄉",
            "蘭嶼鄉",
            "延平鄉",
            "卑南鄉",
            "鹿野鄉",
            "關山鎮",
            "海端鄉",
            "池上鄉",
            "東河鄉",
            "成功鎮",
            "長濱鄉",
            "太麻里鄉",
            "金峰鄉",
            "大武鄉",
            "達仁鄉",
        ],
        花蓮縣: [
            "花蓮市",
            "新城鄉",
            "秀林鄉",
            "吉安鄉",
            "壽豐鄉",
            "鳳林鎮",
            "光復鄉",
            "豐濱鄉",
            "瑞穗鄉",
            "萬榮鄉",
            "玉里鎮",
            "卓溪鄉",
            "富里鄉",
        ],
        金門縣: ["金沙鎮", "金湖鎮", "金寧鄉", "金城鎮", "烈嶼鄉", "烏坵鄉"],
        連江縣: ["南竿鄉", "北竿鄉", "莒光鄉", "東引鄉"],
        澎湖縣: ["馬公市", "西嶼鄉", "望安鄉", "七美鄉", "白沙鄉", "湖西鄉"],
    };

    // 作為 f7 create picker 繫結
    var virtualipt = document.createElement('input');

    // picker
    var picker = {};

    // donel
    var domeEl = $(domeEl);

    // 客製設定
    var custom_setting = (setting && setting["custom"]) || {};

    var new_setting = $.extend(
        true,
        {},
        {
            // Custom Setting
            custom: {
                // 預設值
                // default_value: ['臺北市', '中正區']
                zipcodeEl: '.zipcode',
                cityEl: '.zipcode',
                areaEl: '.zipcode',
            },
            // F7 Picker Setting
            inputEl: "",
            rotateEffect: true,
            renderToolbar: function () {
                return (
                    '<div class="toolbar">' +
                    '<div class="toolbar-inner">' +
                    '<div class="left">' +
                    //'<a href="#" class="link toolbar-clear-link">清除</a>' +
                    "</div>" +
                    '<div class="right">' +
                    '<a href="#" class="link sheet-close popover-close">確定</a>' +
                    "</div>" +
                    "</div>" +
                    "</div>"
                );
            },
            formatValue: function (values, displayValues) {
                // return;
            },
            cols: [
                {
                    textAlign: "center",
                    values: [
                        "基隆市",
                        "臺北市",
                        "新北市",
                        "宜蘭縣",
                        "新竹市",
                        "新竹縣",
                        "桃園市",
                        "苗栗縣",
                        "臺中市",
                        "彰化縣",
                        "南投縣",
                        "嘉義市",
                        "嘉義縣",
                        "雲林縣",
                        "臺南市",
                        "高雄市",
                        "屏東縣",
                        "臺東縣",
                        "花蓮縣",
                        "金門縣",
                        "連江縣",
                        "澎湖縣",
                    ],
                    onChange: function (picker, value, displayValue) {
                        // 更新地區
                        if (picker.cols[1].replaceValues !== undefined) {
                            picker.cols[1].replaceValues(city_refer_region[value]);
                        }
                    },
                },
                {
                    textAlign: "center",
                    values: ["中正區", "大同區"],
                },
            ],
            on: {
                open: function (picker) {
                },
                change: function (picker, value, displayValue) {
                },
            },
        },
        setting
    );

    // reactive value
    var reactiveValue = new_setting.value;

    if (reactiveValue && reactiveValue.length) {
    }

    // 第一個預設值
    new_setting.cols[1].values =
        city_refer_region[new_setting.cols[0].values[0]];

    picker = f7.picker.create($.extend(new_setting, { inputEl: virtualipt }));


    // 觸發開啟
    $(domeEl).on('click', () => {
        picker.open();
    })

    // 事件綁定
    picker.on('open', function (picker) {
    })
    picker.on('change', function (picker, value, displayValue) {
        var reObj = _.find(zipcodes, {
            county: value[0],
            city: value[1]
        })

        if (reObj.id) {
            picker.$inputEl[0].value = reObj.id;
            _triggerPickerInput(picker.$inputEl[0]);
        }

        value.forEach((val, idx) => {
            if (reactiveValue && reactiveValue[idx]) {
                reactiveValue.value = val;
            }
        })
        // picker.$inputEl[1].value = value[0];
        // picker.$inputEl[2].value = value[1];
        // _triggerPickerInput(picker.$inputEl[1]);
        // _triggerPickerInput(picker.$inputEl[2]);
    })

    // 回傳 instance
    return {
        reactiveValues: reactive()
    };
    
}

// Date Picker
function createDatePicker(input) {
    var picker;

    picker = f7.picker.create({
        inputEl: input,
        rotateEffect: true,
        renderToolbar: function () {
            return (
                '<div class="toolbar">' +
                '<div class="toolbar-inner">' +
                '<div class="left">' +
                //'<a href="#" class="link toolbar-clear-link">清除</a>' +
                "</div>" +
                '<div class="right">' +
                '<a href="#" class="link sheet-close popover-close">確定</a>' +
                "</div>" +
                "</div>" +
                "</div>"
            );
        },
        cols: [
            // Years
            {
                textAlign: "left",
                values: GetYearArr(
                    new Date().getFullYear() - 50,
                    new Date().getFullYear()
                ),
            },
            {
                divider: true,
                content: "/",
            },
            // Months
            {
                textAlign: "center",
                values: "01 02 03 04 05 06 07 08 09 10 11 12".split(" "),
            },
            {
                divider: true,
                content: "/",
            },
            // Days
            {
                textAlign: "center",
                values: "01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31".split(
                    " "
                ),
            },
        ],
        formatValue: function (values, displayValues) {
            var yearInput = $(this.$inputEl).find(".input-year");
            var monthInput = $(this.$inputEl).find(".input-month");
            var dateInput = $(this.$inputEl).find(".input-date");

            if (yearInput && monthInput && dateInput) {
                yearInput.val(displayValues[0]);
                monthInput.val(displayValues[1]);
                dateInput.val(displayValues[2]);

                // 支援 Vee-validate
                _triggerPickerInput(yearInput[0], 'change');
                _triggerPickerInput(monthInput[0], 'change');
                _triggerPickerInput(dateInput[0], 'change');
            }
        },
        on: {
            change: function (picker, values, displayValues) {
                var daysInMonth = new Date(
                    picker.value[0],
                    picker.value[1],
                    0
                ).getDate();
                if (values[2] > daysInMonth) {
                    picker.cols[4].setValue(daysInMonth);
                }
            },
        },
    });

    return picker;
}


// 開啟popup
const MukiPopup = {
    get emptyTemplate(){

    },
    get articleTemplate(){
        var temp = $('#popup-default.popup-notice').clone();
        var instance = {
            html: temp[0],
            setTitle(content) {
                temp.find('.title').html(content);

                return instance;
            },
            setContent(content) {
                var content = content || '';
                temp.find('.section-simple').html(content)

                return instance;
            },
        }

        return instance
    }
}

function openPopup(setting) {
    var newSetting = $.extend({
        content: '#popup-default',
        on: {
            opened: function () {
            }
        }
    }, setting);

    var popup = f7.popup.create(newSetting);

    var custom = newSetting.custom;

    if (newSetting.custom) {
        // popup 標題
        if (custom.title) {
            $(popup.$el).find('.navbar .title').text(custom.title)
        }

        // popup 確認按鈕
        if (custom.onConfirm) {
            $(popup.$el).find('.popup-confirm').click(function () {
                custom.onConfirm();
            })
        }
    }

    // 取消按鈕
    $(popup.$el).find('.popup-cancel').click(function () {
        f7.popup.close(popup.$el);
    });

    // 確認按鈕
    $(popup.$el).find('.popup-confirm').click(function () {
        f7.popup.close(popup.$el);
    })

    setTimeout(function () {
        popup.open();
    }, 0)

    return popup;
}


export { 
    MukiUtil,
    RandomFunc, 
    StrFunc, 
    ViewFunc, 
    Counter,

    BasePicker,
    Date_picker,

    GetYearArr,
    GetMonthArr,
    GetDateArr,

    // css class 處理
    stringArrToClass,

    // picker
    createCityRegionPicker,
    reactiveCityRegionPicker,

    createDatePicker,
    // popup
    MukiPopup,
    openPopup,
};
