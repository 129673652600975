import { useField } from "vee-validate";
import { computed } from "vue";
export default {
  components: {},
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      default: ""
    },
    value: {
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    size: {
      default: 20
    },
    name: {
      type: String,
      default: ""
    },
    rules: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      default: false
    },
    inline: Boolean,
    f7: {
      type: String,
      default: ""
    },
    material: {
      type: String,
      default: ""
    },
    ios: {
      type: String,
      default: ""
    },
    md: {
      type: String,
      default: ""
    },
    aurora: {
      type: String,
      default: ""
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  setup: function setup(props) {
    var type = props.type,
        modelValue = props.modelValue,
        value = props.value,
        name = props.name;
    var rootClassName = computed(function () {
      return "base-".concat(type);
    });
    var iconWrapClassName = computed(function () {
      return "icon-".concat(type, "-wrap");
    });
    var textClassName = computed(function () {
      return "".concat(type, "-text");
    });
    var thisModelValue = computed(function () {
      return parseInt(props.modelValue);
    });

    var changeHandler = function changeHandler() {}; // validate


    var _useField = useField(name),
        thisErrMsg = _useField.errorMessage,
        thisName = _useField.name,
        thisValue = _useField.value,
        meta = _useField.meta,
        errors = _useField.errors,
        resetField = _useField.resetField,
        validate = _useField.validate,
        handleChange = _useField.handleChange,
        handleBlur = _useField.handleBlur,
        setValidationState = _useField.setValidationState,
        thisChecked = _useField.checked;

    var isChecked = computed(function () {
      return thisModelValue.value === parseInt(value);
    });
    return {
      rootClassName: rootClassName,
      iconWrapClassName: iconWrapClassName,
      textClassName: textClassName,
      changeHandler: changeHandler,
      isChecked: isChecked,
      thisErrMsg: thisErrMsg
    };
  }
};