// Import Vue
import { createApp } from 'vue';

// Import Framework7
import Framework7, { Dom7 } from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents, f7, f7ready } from 'framework7-vue/bundle';

import store from '@/store/store.js';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/app.scss';

import { upperFirst, camelCase } from "lodash";

// Import App Component
import App from '../components/app.vue';

// 表單驗證
import validations from "@/js/validations.js";

// 圖形驗證
// import BaseIdentify from "@/components/BaseIdentify";

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
const app = createApp(App);

// store
app.use(store)
window.store = store;

// 常數
const mukiConst ={
    // 身分
    memberType:{
        // 總代
        GENERAL_AGENT: 1,
        // 區代
        REGIONAL_AGENT: 2,
        // 經銷
        DEALER: 3,
        // 一般會員
        GENERAL_MEMBER: 4,
    },
    // 會員
    memberCategory:{
        // 散購
        CASUAL_BUY: 5
    },
    // 性別
    gender: {
        FEMALE: 0,
        MALE: 1,
        EMPTY: 2
    },
    // 付款狀態
    orderStatus: {

    },
    // 發票
    invoiceType: {
        // 2聯
        DUPLICATE_UNIFORM_INVOICE: 1,
        // 3聯
        TRIPLICATE_UNIFORM_INVOICE: 2,
        // 捐贈
        DONATE: 3,
    },
    // 付款方式
    paymentMethod: {
        // 刷卡
        CARD: 0,
        // LINE PAY
        LINE_PAY: 1,
        // 匯款
        REMIT: 2,
        // 點數
        POINT: 3,
        // 分期/與公司聯絡
        INSTALLMENT_AND_CONTACT_CS: 4,
    },
    againApply: {
        YES_AGAIN_APPLY_ID: 1,
        NO_AGAIN_APPLY_ID: 0
    },
    // 驗證
    token:{
        INVALID: -2
    },
    // 購買型態 : 散購、任選
    buyManner: {
        // 任選
        NORMAL_PURCHASE: 1,
        // 散購
        BULK_PURCHASE: 2
    }
}
app.provide('mukiConst', mukiConst);
window.mukiConst = mukiConst


// 定義全域可取用的components
function importAll(requireComponent) {
    requireComponent.keys().forEach((fileName) => {
        const componentConfig = requireComponent(fileName);

        const componentName = upperFirst(
            camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
        );

        app.component(
            componentName,
            componentConfig.default || componentConfig
        );
    });
}

importAll(require.context("@/components", false, /Base[A-Z]\w+\.(vue|js)$/));

importAll(
    require.context("@/components/form", false, /Base[A-Z]\w+\.(vue|js)$/)
);

// Register Framework7 Vue components
registerComponents(app);

// F7 可使用時
f7ready(() => {
    // Framework7's initialized instance
    window.f7 = f7;
    window.$$ = Dom7;
})

// Mount the app
app.mount('#app');



