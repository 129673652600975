import { baseGet, basePost } from "@/js/services/baseService";
import { computed, reactive } from "vue";
import { useStore } from 'vuex'

const memberDataList = [
    'id',
    'token',
    'member_name',
    // 會員加入時間
    'agent_time',
    // 會員身分
    'category_name',
    // 會員身分id (5: 散購會員 、 1 : 一般會員)
    'category_id',
    'mobile',
    'invite_code',
    'gender',
    'zip_code',
    'city_name',
    'area_name',
    'address',
    'isLogined'
]

export default {
    namespaced: true,

    state: () => ({
        // 會員id
        id: null,
        // 驗證
        // 宗翰 : 這個token符合，就會再延長1小時
        // 只要有api是用到token，他判斷這個token符合，就會再延長1小時
        token: null,
        member_name: null,
        // 會員加入時間
        agent_time: null,
        // 會員身分
        category_name: null,
        category_id: null,
        mobile: null,
        invite_code: null,
        gender: null,
        zip_code:null,
        city_name:null,
        area_name: null,
        address: null,
        isLogined: null,
    }),
    getters: {
        // 已登入
        isLogined(state, getters){
            return state.isLogined;
        },
        // 詳細
        authInfo(state, getters){
            return reactive({
                id: computed(() => state.id),
                token: computed(() => state.token),
                member_name: computed(() =>state.member_name),
                category_name: computed(() =>state.category_name),
                category_id: computed(() =>state.category_id),
                mobile: computed(() => state.mobile),
                invite_code: computed(() => state.invite_code),
                isLogined: computed(() => state.isLogined),
                agent_time: computed(() => state.agent_time)
            })
        }
    },
    actions: {
        login({commit}, data) {
            commit('updateMemberData', data)
        },
        logout({ commit }, data) {
            var emptyObj = {
            }
            memberDataList.forEach(name => {
                emptyObj[name] = '';
            });

            commit('updateMemberData', emptyObj)
        },
        init({commit}){
            var dataObj = {

            }

            memberDataList.forEach(name => {
                let data = localStorage.getItem(name)
                if ( data ) {
                    try {
                        data = (data && JSON.parse(data)) || null;
                        dataObj[name] = data
                    } catch(err){

                    }
                }
            });

            commit('updateMemberData', dataObj)
        },
        // 抓最新的會員資料
        getLatestMemberData({commit}){
            const authInfo = store.getters['auth/authInfo'];
            const toUpdate = {}

            baseGet('/member/profile/?' + f7.utils.serializeObject({
                mobile: authInfo.mobile,
                token: authInfo.token,
            })).then(res=>{
                const { data, status } = res;
                const resData = data['res_data'];

                if (status === 200 && data["res_code"] === 1) {
                    if ( resData.agent_time ) {
                        toUpdate['agent_time'] = resData.agent_time
                    }
                    if ( resData.category_name ) {
                        toUpdate['category_name'] = resData.category_name
                    }

                    commit('updateMemberData', toUpdate)
                }
            })
        }
    },
    mutations: {
        updateMemberData(state, data){
            memberDataList.forEach(name => {
                
                if ( data[name] ) {
                    state[name] = data[name];
                    localStorage.setItem(name, JSON.stringify(data[name]))
                } else if (data[name] == '') {
                    state[name] = '';
                    localStorage.setItem(name, '')
                }
            });
        },
    },
}

