import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  class: "item-content item-input"
};
var _hoisted_2 = {
  class: "item-inner"
};
var _hoisted_3 = {
  key: 0,
  class: "item-title item-label"
};
var _hoisted_4 = {
  class: "label-txt"
};
var _hoisted_5 = {
  key: 0,
  class: "star-before"
};
var _hoisted_6 = {
  key: 1,
  class: "star-after"
};
var _hoisted_7 = {
  class: "item-input-wrap-outer"
};
var _hoisted_8 = {
  class: "item-input-wrap"
};
var _hoisted_9 = {
  key: 0,
  class: "custom-controls-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_f7_icon = _resolveComponent("f7-icon");

  return _openBlock(), _createBlock("div", {
    class: ["base-input", $setup.computeStyStr ? $setup.computeStyStr : null]
  }, [_renderSlot(_ctx.$slots, "root-start"), _createVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "content-start"), _createVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "inner-start"), $props.labelName ? (_openBlock(), _createBlock("div", _hoisted_3, [_renderSlot(_ctx.$slots, "label-start"), _createVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString($props.labelName) + " ", 1), $props.star == 'star-before' ? (_openBlock(), _createBlock("span", _hoisted_5, "*")) : _createCommentVNode("", true), $props.star == 'star-after' ? (_openBlock(), _createBlock("span", _hoisted_6, "*")) : _createCommentVNode("", true)]), _renderSlot(_ctx.$slots, "label-end")])) : _createCommentVNode("", true), !$setup.isCustom ? (_openBlock(), _createBlock(_Fragment, {
    key: 1
  }, [_createVNode("div", _hoisted_7, [_renderSlot(_ctx.$slots, "wrap-start"), _createVNode("div", _hoisted_8, [_renderSlot(_ctx.$slots, "input-start"), $props.f7 ? (_openBlock(), _createBlock(_component_f7_icon, {
    key: 0,
    f7: $props.f7,
    material: $props.material,
    ios: $props.ios,
    md: $props.md,
    aurora: $props.aurora
  }, null, 8, ["f7", "material", "ios", "md", "aurora"])) : _createCommentVNode("", true), $props.icon ? (_openBlock(), _createBlock("i", {
    key: 1,
    class: ["icon", $props.icon]
  }, null, 2)) : _createCommentVNode("", true), (_openBlock(), _createBlock(_resolveDynamicComponent($props.inputTag), {
    value: $props.modelValue,
    onInput: _cache[1] || (_cache[1] = function ($event) {
      return [_ctx.$emit('update:modelValue', $event.target.value)];
    }),
    name: $props.name,
    type: $props.type,
    inputmode: $props.inputmode,
    placeholder: $props.placeholder,
    id: $props.inputId,
    class: $props.inputClass,
    readonly: $props.readonly,
    disabled: $props.disabled
  }, null, 8, ["value", "name", "type", "inputmode", "placeholder", "id", "class", "readonly", "disabled"])), _renderSlot(_ctx.$slots, "input-end")]), $setup.isSelect ? (_openBlock(), _createBlock(_component_f7_icon, {
    key: 0,
    class: "select-chevron-down",
    f7: "chevron_down"
  })) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "wrap-end")]), $setup.isCustomControls ? (_openBlock(), _createBlock("div", _hoisted_9, [_renderSlot(_ctx.$slots, "custom-controls")])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default"), _renderSlot(_ctx.$slots, "inner-end")]), _renderSlot(_ctx.$slots, "content-end")]), $props.errMsg || $setup.thisErrMsg ? (_openBlock(), _createBlock("span", {
    key: 0,
    class: "error-msg",
    name: $props.name
  }, _toDisplayString($props.errMsg ? $props.errMsg : $setup.thisErrMsg), 9, ["name"])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "root-end")], 2);
}