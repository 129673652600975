import { baseGet, basePost } from "@/js/services/baseService";
import { data } from "jquery";
import { computed } from "vue";

export default {
    namespaced: true,
    state: () => ({
        // 各步
        isStep1Done: null,
        isStep2Done: null,
        isStep3Done: null,
        isStep4Done: null,
        // 會員id
        id: null,
        // 是否是繳費失敗
        isPaymentFail: null,
        // 啟動碼
        invite_code: null,
        // 表單資料
        mobile: null,
        // 驗證碼
        auth_code: null,
        password: null,
        confirm_password: null,
        identity_number: null,
        member_name: null,
        gender: null,
        birthday_year: null,
        birthday_month: null,
        birthday_day: null,
        zip_code: null,
        city_name: null,
        area_name: null,
        address: null,
        idcard1_pic_src: null,
        idcard2_pic_src: null,
        bank_pic_src: null,
        bank_name: null,
        bank_subname: null,
        bank_account: null,

        // 第3步，確認資料
        initiation_amount: 7, // 註冊要選擇的產品數量 "必須等於" 此數值 (不可多、不可少)
        initiation_fee_format: null, // 入會費 (固定值，使用api收到數字，跟產品數量無關)
        freebie:{
            // 買每num個產品則贈送贈品
            num: null,
            id: null,
            spec_id: null,
            pic_src: null,
            // 庫存
            stock: null,
        },
        products:[
            // 格式 :
            // {
            //     id: null,
            //     name: null,
            //     // 購買數量
            //     orderNum: 1,
            //     ori_price: 1280,
            //     ori_price_format: null,
            //     pic_src: null,
            //     product_type: null,
            //     spec_id: null,
            //     // 庫存
            //     stock: null
            // }
        ],
        // 第4步，前往付費
    }),
    getters: {
        // 購買失敗
        isPaymentFail(state){
            return state.isPaymentFail;
        },
        // 邀請碼
        inviteCode(state) {
            return state.invite_code;
        },
        // 是否為再次申請
        isAgainApply(state) {
            if ( !state.isPaymentFail == true ) {
                // 狀態 : 初次申請
                return 0;
            } else {
                // 狀態 : 繳費失敗重新填寫申請
                return 1
            }
        },
        // 會員註冊=>選擇的產品資料
        memberConfirmData(state, getters){
            return computed( () => {
                return {
                    initiation_amount: state.initiation_amount,
                    initiation_fee_format: state.initiation_fee_format,
                    freebie: state.freebie,
                    // products array 不含 freebie
                    productsNoFreebie: state.products,
                    // producst array
                    products: [
                    // 贈品
                    {
                        id: state.freebie.id,
                        spec_id: state.freebie.spec_id,
                        orderNum: getters.totalOrderFreebies.value,
                    }, ...state.products]
                } 
            })
        },
        // 第三步
        totalOrderNum(state) {
            return computed(() => {
                var result = 0;
                state.products.forEach((item, idx) => {
                    result += (item.orderNum ? parseInt(item.orderNum) : 0);
                });
                return result;
            })
        },
        // 目前可獲贈的贈品數量
        totalOrderFreebies(state, getters) {
            return computed(() => {
                if ( !state.freebie ) {
                    return 0;
                }
                if ( !state.freebie.num ) {
                    return 0;
                }
                return Math.floor( getters.totalOrderNum.value / state.freebie.num );
            })
        },
        // 當前應付金額
        totalOrderPayment(state) {
            return computed(() => {
                var result = 0;
                state.products.forEach((item, idx) => {
                    result += item.ori_price * item.orderNum;
                });

                return result;
            })
        },
        // 是否達到最少購買門檻
        // 2021/8/10 [廢除] 規則已改變為必須相等於設定值
        // reachMiniumOrderNum(state, getters) {
        //     return getters.totalOrderNum.value >= state.initiation_amount;
        // }
    },
    actions: {
        async getMemberConfirmData({ commit }, dataObj) {
            return baseGet('/member/confirm').then(res => {
                const { data, status } = res;
                const resData = data['res_data']
                if (status === 200 && data["res_code"] === 1) {
                    commit('updateMemberConfirmData', resData)
                } else {
                    console.log(data["res_content"], "訊息提示");
                }
            })
        },
        // 檢查選擇的所有產品庫存
        checkSelectedProductStock({ commit , state}){

            var pass = true;
            var stockFailArr = []

            return new Promise((resolve, reject)=>{
                baseGet('/member/confirm').then(res => {
                    const { data, status } = res;
                    const resData = data['res_data']
                    if (status === 200 && data["res_code"] === 1) {
    
                        var products = resData.products;
    
                        // 有已選擇的資料
                        if ( state.products ) {
                            // 檢查當前輸入的數量是否超過庫存
                            state.products.forEach(selectedProduct => {
                                products.forEach(product =>{
                                    if ( selectedProduct.id == product.id ) {
                                        if ( selectedProduct.orderNum ){
                                            // 有輸入數量
                                            if ( selectedProduct.orderNum > product.stock ){
                                                // 超過庫存
                                                product.orderNum = product.stock;
                                                stockFailArr.push(selectedProduct)

                                                pass = false;
                                            } else {
                                                product.orderNum = selectedProduct.orderNum
                                            }
                                        } else {
                                            product.orderNum = 0;
                                        }
                                    }
                                })
                            })
                        }
    
                        commit('updateMemberConfirmData', resData)
    
                    } else {
    
                        console.log(data["res_content"], "訊息提示");
                    }
                }).finally(()=>{

                    resolve({
                        pass,
                        stockFailArr
                    })
                })
            })
        }
    },
    mutations: {
        // 第一步
        isStep1Done(state, status){
            state.isStep1Done = status;
        },
        // 購買失敗狀態
        updatePaymentFail(state, status) {
            state.isPaymentFail = status;
        },
        // 邀請碼
        updateInviteCode( state, data ) {
            state.invite_code = data;
        },
        // 註冊資料
        updateRegisterData(state, dataObj) {
            // 會員id
            for (var key in dataObj ) {
                // update vallue
                if ( state.hasOwnProperty(key) ) {
                    state[key] = dataObj[key]
                }
            }
        },
        // 第2步
        isStep2Done(state, status) {
            state.isStep2Done = status;
        },
        // 第3步，確認資料
        isStep3Done(state, status) {
            state.isStep3Done = status;
        },
        // 購買確認相關資料
        updateMemberConfirmData(state, dataObj) {
            // 註冊要選擇的產品數量 "必須等於" 此數值 (不可多、不可少)
            if ( dataObj.initiation_amount !== undefined  ){
                state.initiation_amount = dataObj.initiation_amount;
            }
            // 入會費 (固定金額，不會變動)
            if (  dataObj.initiation_fee_format !== undefined  ) {
                state.initiation_fee_format = dataObj.initiation_fee_format;
            }
            // freebie
            if (  dataObj.freebie !== undefined  ) {
                if ( dataObj.freebie.num !== undefined  ) {
                    state.freebie.num = dataObj.freebie.num;
                }
                if ( dataObj.freebie.id !== undefined  ){
                    state.freebie.id = dataObj.freebie.id;
                }
                if ( dataObj.freebie.spec_id !== undefined  ) {
                    state.freebie.spec_id = dataObj.freebie.spec_id;
                }
                if (dataObj.freebie.name !== undefined ){
                    state.freebie.name = dataObj.freebie.name;
                }
                if (dataObj.freebie.pic_src !== undefined ) {
                    state.freebie.pic_src = dataObj.freebie.pic_src;
                }
                if (dataObj.freebie.stock !== undefined ){
                    state.freebie.stock = dataObj.freebie.stock;
                }
            }
            // products
            if ( dataObj.products !== undefined ) {
                dataObj.products.forEach((item , idx) => {
                    if (!item.orderNum) {
                        item.orderNum = 0
                    };
                })
                state.products = dataObj.products;
            }
        },
        // 更新產品的購買數量
        updateProductNum(state, data) {
            var id = data.id;
            var product;
            
            state.products.some((val, idx) => {
                if ( val.id == id) {
                    product = val;
                    return;
                }
            });
            product.orderNum = data.orderNum;
        },
        /**
         * 重設註冊流程內產品的購買數量
         * @param {*} newOrderNum 
         */
        resetAllProductOrderNum(state, data){
            var newOrderNum = (data && data.newOrderNum) || 0;
            state.products.forEach((product, idx) => {
                product.orderNum = newOrderNum;
            });
        },
        updateAmt(state, dataObj){
            state.freebie.num = 100
        },
        // 第4步
        isStep4Done(state, status) {
            state.isStep4Done = status;
        },
    },
}

