import store from '../store/store.js'

import Index from '../pages/Index.vue';
import Login from '../pages/Login.vue';
import NotFoundPage from '../pages/404.vue';

function checkAuth({ to, from, resolve, reject }) {
  var isLogined = store.getters['auth/isLogined'];
  if (isLogined) {
    resolve();
  } else {
    reject();
    f7.dialog.alert('請先登入!', "訊息提示", () => {
      _direct_url('/')
    });
  }
}
function checkPermission({ to, from, resolve, reject }) {
}

let methods = {
  async checkAuth() {
    store.commit('account/LOAD')
    return Promise.resolve(store.getters['account/IS_LOGIN'])
  },
  async checkRole(role) {
    return Promise.resolve(store.getters['account/WITH_ROLE'](role))
  },
  async checkToken(type, token) {
    try {
      let response = await axios.put(`/api/Account/Token/Verify?token=${token}&type=${type}`)
      console.log('response : ' + response);
      return response.status === 200
    } catch (error) {
      return false
    }
  }
}

var routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/index',
    name: 'index',
    component: Index
  },
  {
    path: '/forgot_pwd',
    name: 'forgotPwd',
    asyncComponent: () => import("@/pages/ForgotPwd"),
  },
  {
    // 忘記密碼，修改密碼
    path: '/change_pwd',
    name: 'ChangePwd',
    asyncComponent: () => import("@/pages/ChangePwd"),
  },
  {
    path: '/register_step1',
    name: 'RegisterStep1',
    asyncComponent: () => import("@/pages/RegisterStep1"),
  },
  {
    path: '/register_step2',
    name: 'RegisterStep2',
    asyncComponent: () => import("@/pages/RegisterStep2"),
    beforeEnter({ to, from, resolve, reject }){
      if ( !store.state.register.isStep1Done ) {
        reject()
        _direct_url('/')
      } else {
        resolve()
      }
    }
  },
  {
    path: '/register_step3',
    name: 'RegisterStep3',
    asyncComponent: () => import("@/pages/RegisterStep3"),
    beforeEnter({ to, from, resolve, reject }) {
      if (!store.state.register.isStep2Done) {
        reject()
        _direct_url('/')
      } else {
        resolve()
      }
    }
  },
  {
    path: '/register_step4',
    name: 'RegisterStep4',
    asyncComponent: () => import("@/pages/RegisterStep4"),
    beforeEnter({ to, from, resolve, reject }) {
      if (!store.state.register.isStep3Done) {
        reject()
        _direct_url('/')
      } else {
        resolve()
      }
    }
  },
  {
    path: '/product_list',
    name: 'ProductList',
    asyncComponent: () => import("@/pages/ProductList"),
  },
  {
    path: '/product_list_for_casual_buy',
    name: 'productListForCasualBuy',
    asyncComponent: () => import("@/pages/productListForCasualBuy"),
  },
  {
    path: '/product_detailed/id/:id',
    name: 'product_detailed',
    asyncComponent: () => import("@/pages/ProductDetailed"),
  },
  // 購物車
  {
    path: '/cart_step1',
    name: 'CartStep1',
    asyncComponent: () => import("@/pages/CartStep1"),
  },
  {
    path: '/cart_step1_for_casual_buy',
    name: 'CartStep1ForCasualBuy',
    asyncComponent: () => import("@/pages/CartStep1ForCasualBuy"),
  },
  {
    path: '/order_complete',
    name: 'OrderComplete',
    asyncComponent: () => import("@/pages/OrderComplete"),
  },
  // 消息
  {
    path: '/news_list/category/:category',
    name: 'NewsList',
    asyncComponent: () => import("@/pages/NewsList"),
  },
  {
    path: '/news_detailed/id/:id/category/:category',
    name: 'NewsDetailed',
    asyncComponent: () => import("@/pages/NewsDetailed"),
  },
  // {
  //   // 分享頁
  //   path: '/news_detailed_share/id/:id/category/:category',
  //   name: 'NewsDetailedShare',
  //   asyncComponent: () => import("@/pages/NewsDetailedShare"),
  // },
  {
    // 分享頁測試用
    path: '/news_detailed_share/id/:id/category/:category',
    name: 'NewsDetailedShare',
    asyncComponent: () => import("@/pages/NewsDetailedShare"),
  },
  {
    // 框架頁
    path: '/standalone',
    name: 'StandAlone',
    asyncComponent: () => import("@/pages/NewsDetailed"),
    // async({ resolve }) {
      // var realUrl = location.hash.replace('#!', '');
      // console.log('load dynamic', location)
      // // dynamic import component; returns promise
      // const vueComponent = () => import('./pages/about.vue');
      // // // resolve promise
      // // vueComponent().then((vc) => {
      // //   // resolve with component
      // //   resolve({ component: vc.default })
      // // });
    // },
  },
  {
    path: '/notification_list/id/:id/name/:name/page/:page',
    name: 'NotificationList',
    asyncComponent: () => import("@/pages/NotificationList"),
  },
  {
    // 刪除選擇列表
    path: '/notification_del_list',
    name: 'NotificationDelList',
    asyncComponent: () => import("@/pages/NotificationDelList"),
  },
  {
    path: '/notification_detailed/id/:id/backPageId/:backPageId/backPageName/:backPageName/backPagePage/:backPagePage',
    name: 'NotificationDetailed',
    asyncComponent: () => import("@/pages/NotificationDetailed"),
  },
  // 訂購
  {
    path: '/order_list',
    name: 'OrderList',
    asyncComponent: () => import("@/pages/OrderList"),
  },
  {
    path: '/order_detailed/id/:id',
    name: 'OrderDetailed',
    asyncComponent: () => import("@/pages/OrderDetailed"),
  },
  // 我的帳戶
  {
    path: '/my_account',
    name: 'MyAccount',
    asyncComponent: () => import("@/pages/MyAccount"),
    routes: [
      {
        // 購買方案
        path: '/deposit',
        name: 'Deposit',
        asyncComponent: () => import("@/pages/Deposit"),
      },
      {
        // 推薦紀錄
        path: '/recommends_list',
        name: 'RecommendsList',
        asyncComponent: () => import("@/pages/RecommendsList"),
      },
      {
        // 獎金紀錄
        path: '/bonus_list',
        name: 'BonusList',
        asyncComponent: () => import("@/pages/BonusList"),
        beforeEnter: checkAuth,
      },
      {
        // 我的組織
        path: '/organization',
        name: 'Organization',
        asyncComponent: () => import("@/pages/Organization"),
      },
      {
        // 我的啟動碼
        path: '/my_activication_code',
        name: 'MyActivicationCode',
        asyncComponent: () => import("@/pages/MyActivicationCode"),
        on: {
          pageBeforeIn: function () {
          }
        }
      },
      {
        // 會員登入中修改密碼
        path: '/member_change_pwd',
        name: 'MemberChangePwd',
        asyncComponent: () => import("@/pages/MemberChangePwd"),
      },
    ]
  },
  // 身分證與帳戶資料
  {
    path: '/identity_verify',
    name: 'IdentityVerify',
    asyncComponent: () => import("@/pages/IdentityVerify"),
  },
  // 變更個人資料
  {
    path: '/change_profile',
    name: 'ChangeProfile',
    asyncComponent: () => import("@/pages/ChangeProfile"),
  },
  // 無法購買
  {
    path: '/unable_to_purchase',
    name: 'UnableToPurchase',
    asyncComponent: () => import("@/pages/UnableToPurchase"),
  },
  // 錢包
  {
    path: '/wallet',
    name: 'Wallet',
    asyncComponent: () => import("@/pages/Wallet"),
  },
  {
    // 點數轉點
    path: '/turn_point',
    name: 'TurnPoint',
    asyncComponent: () => import("@/pages/TurnPoint"),
  },
  {
    // 退換貨
    path: '/return_and_refunds',
    name: 'ReturnAndRefunds',
    asyncComponent: () => import("@/pages/ReturnAndRefunds"),
  },
  

  {
    // 錯誤
    path: '/err',
    name: 'Err',
    asyncComponent: () => import("@/pages/Err"),
    
  },
  // {
  //   path: '/order_detailed',
  //   name: 'OrderDetailed',
  //   asyncComponent: () => import("@/pages/OrderDetailed"),
  // },
  // {
  //   path: '/about/',
  //   component: AboutPage,
  // },
  // {
  //   path: '/form/',
  //   component: FormPage,
  // },
  // {
  //   path: '/catalog/',
  //   component: CatalogPage,
  // },
  // {
  //   path: '/product/:id/',
  //   component: ProductPage,
  // },
  // {
  //   path: '/settings/',
  //   component: SettingsPage,
  // },

  // {
  //   path: '/dynamic-route/blog/:blogId/post/:postId/',
  //   component: DynamicRoutePage,
  // },
  // {
  //   path: '/request-and-load/user/:userId/',
  //   async: function ({ router, to, resolve }) {
  //     // App instance
  //     var app = router.app;

  //     // Show Preloader
  //     app.preloader.show();

  //     // User ID from request
  //     var userId = to.params.userId;

  //     // Simulate Ajax Request
  //     setTimeout(function () {
  //       // We got user data from request
  //       var user = {
  //         firstName: 'Vladimir',
  //         lastName: 'Kharlampidi',
  //         about: 'Hello, i am creator of Framework7! Hope you like it!',
  //         links: [
  //           {
  //             title: 'Framework7 Website',
  //             url: 'http://framework7.io',
  //           },
  //           {
  //             title: 'Framework7 Forum',
  //             url: 'http://forum.framework7.io',
  //           },
  //         ]
  //       };
  //       // Hide Preloader
  //       app.preloader.hide();

  //       // Resolve route to load page
  //       resolve(
  //         {
  //           component: RequestAndLoad,
  //         },
  //         {
  //           props: {
  //             user: user,
  //           }
  //         }
  //       );
  //     }, 1000);
  //   },
  // },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export {checkAuth};
export default routes;
