import axios from "axios";
import store from '../../store/store';
import { f7 } from "framework7-vue";

const apiBase = axios.create({
    baseURL: "/webapi/",
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});
const mockApiBase = axios.create({
    baseURL: "/mockWebApi/",
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const tokenInvalidCheck = (res) => {
    const { data } = res;
    // token過期
    if (data['res_code'] == mukiConst.token.INVALID) {
        if (store.state.isViewReady) {
            f7.dialog.alert(data["res_content"], "訊息提示", () => {
                store.dispatch('auth/logout');
                directURL('/');
            });
        } else {
            store.dispatch('auth/logout');
            directURL('/');
            throw 'token過期'
        }
    }
    return res;
}

// 登入
function postLogin(url, formInfo, mode) {
    var promise = {}
    if(mode === 'dev') {
        promise = mockApiBase.post(url, formInfo).then(tokenInvalidCheck);
    } else {
        promise = apiBase.post(url, formInfo).then(tokenInvalidCheck);
    }

    try {
        return promise.then((res) => {
            const { data, status } = res;
            const resData = data['res_data']

            if (status === 200 && data["res_code"] === 1) {
                
                store.dispatch("auth/login", {
                    // 會員id
                    id: resData.id,
                    // 驗證
                    token: resData.token,
                    member_name: resData.member_name,
                    // 會員加入時間
                    agent_time: resData.agent_time,
                    category_name: resData.category_name,
                    category_id: parseInt(resData.category_id),
                    invite_code: resData.invite_code,
                    // 直接由輸入值取得
                    mobile: String(formInfo.mobile).trim(),
                    gender: resData.gender,
                    zip_code: resData.zip_code,
                    city_name: resData.city_name,
                    area_name: resData.area_name,
                    address: resData.address,
                    isLogined: true,
                });
    
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示");
            }
            
            return res;
        })
        .catch((err) => {
            console.log('err', err)
            f7.dialog.alert(
                "伺服器錯誤，請聯繫維護人員協助處理",
                "訊息提示"
            );
        });
    } catch(err) {
        console.log('postLogin 發生錯誤')
    }
}

// 通用
function baseGet(url, formInfo, mode) {
    if ( mode === 'dev' ) {
        return mockApiBase.get(url, formInfo).then(tokenInvalidCheck);
    } else {
        return apiBase.get(url, formInfo).then(tokenInvalidCheck);
    }
}

function basePost(url, formInfo, mode) {
    if(mode === 'dev') {
        return mockApiBase.post(url, formInfo).then(tokenInvalidCheck);
    } else {
        return apiBase.post(url, formInfo).then(tokenInvalidCheck);
    }
}

function basePut(url, formInfo, mode) {
    if(mode === 'dev') {
        return mockApiBase.put(url, formInfo).then(tokenInvalidCheck);
    } else {
        return apiBase.put(url, formInfo).then(tokenInvalidCheck);
    }
}

function basePatch(url, formInfo, mode) {
    if(mode === 'dev') {
        return mockApiBase.patch(url, formInfo).then(tokenInvalidCheck);
    } else {
        return apiBase.patch(url, formInfo).then(tokenInvalidCheck);
    }
}

function baseDel(url, formInfo, mode) {
    if(mode === 'dev') {
        return mockApiBase.delete(url, formInfo).then(tokenInvalidCheck);
    } else {
        return apiBase.delete(url, formInfo).then(tokenInvalidCheck);
    }
}

export { postLogin, baseGet, basePost, basePut, basePatch, baseDel, };
