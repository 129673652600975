import { createVNode as _createVNode, resolveComponent as _resolveComponent, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_f7_icon = _resolveComponent("f7-icon");

  return _withDirectives((_openBlock(), _createBlock("label", {
    class: [$setup.rootClassName, $props.inline ? 'custom-control-inline' : null, $props.size ? "size-".concat($props.size, "px") : '']
  }, [_createVNode("input", {
    value: $props.value,
    onChange: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('update:modelValue', $event.target.checked ? $event.target.value : '');
    }),
    checked: $setup.isChecked,
    type: $props.type,
    rules: $props.rules,
    name: $props.name
  }, null, 40, ["value", "checked", "type", "rules", "name"]), _createVNode("span", {
    class: [$setup.iconWrapClassName]
  }, [_createVNode(_component_f7_icon, {
    icon: $props.icon,
    f7: $props.type == 'checkbox' ? 'checkmark_alt' : null,
    material: $props.material,
    ios: $props.ios,
    md: $props.md,
    aurora: $props.aurora
  }, null, 8, ["icon", "f7", "material", "ios", "md", "aurora"])], 2), _createVNode("span", {
    class: $setup.textClassName
  }, [_renderSlot(_ctx.$slots, "default")], 2)], 2)), [[_vShow, $props.isShow]]);
}