import { f7 } from "framework7-vue";
import { useStore } from 'vuex';
import { ref, onMounted } from "vue";
import { baseGet, basePost } from "@/js/services/baseService";
export default {
  components: {},
  props: {
    f7route: Object,
    f7router: Object
  },
  setup: function setup(props) {
    // 預設false，有需要在手動開啟
    f7.view.main.params.stackPages = false;
    var f7router = props.f7router,
        f7route = props.f7route; // store

    var store = useStore(); // auth

    var authInfo = store.getters['auth/authInfo'];
    var submitThisForm = {};
    var banner = ref([]);
    var textEditor = ref([]); // app 驗證用
    // if ( 
    //   ['0911221123'].indexOf(store.state.auth.mobile) > -1 
    // ) {
    //   store.commit('updateCommon', {
    //     appStoreReviewAccount: true
    //   })
    // }
    // app 驗證用 /
    // 後端取得 mobile info 用

    getMobileInfo(); // 檢查並更新會員身分、資料

    if (store.state.auth.mobile && store.state.auth.token) {
      baseGet("/member/member_program?" + f7.utils.serializeObject({
        mobile: store.state.auth.mobile,
        token: store.state.auth.token
      }), {}).then(function (res) {
        if (res) {
          var data = res.data,
              status = res.status;
          var resData = data['res_data'];

          if (status === 200 && data["res_code"] === 1) {
            var toUpdate = {
              category_id: parseInt(resData.member_category_id),
              category_name: resData.category_name,
              zip_code: resData.zip_code,
              city_name: resData.city_name,
              area_name: resData.area_name,
              address: resData.address,
              agent_time: resData.agent_time
            };
            store.commit('auth/updateMemberData', toUpdate);
          }
        }
      });
    }

    baseGet('/home?' + f7.utils.serializeObject({
      mobile: authInfo.mobile,
      token: authInfo.token
    }), {}).then(function (res) {
      var data = res.data,
          status = res.status;
      var resData = data["res_data"];

      if (status === 200 && data["res_code"] === 1) {
        if (resData['banner']) {
          banner.value = resData['banner'];
        }

        if (resData['text_editor']) {
          textEditor.value = resData['text_editor'];
        }
      } else {}

      setViewReady();
    });
    var unread = ref(0);
    store.dispatch('getMsgUnread').then(function (res) {
      unread.value = parseInt(res);
    });

    var dateDisplay = function dateDisplay(dateStr) {
      if (dateStr) {
        return dateStr.replace(/-/g, ' / ');
      } else {
        return '';
      }
    };

    onMounted(function () {});
    return {
      submitThisForm: submitThisForm,
      unread: unread,
      banner: banner,
      textEditor: textEditor,
      dateDisplay: dateDisplay,
      m_open_window: m_open_window
    };
  }
};