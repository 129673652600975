import { defineRule, configure } from "vee-validate";
import { confirmed, required, email } from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import { f7 } from "framework7-vue";
import store from "@/store/store.js";

function isNullOrUndefinedOrEmpty(val){
    return val === null || val === undefined || val === '';
}

function trimValue(value) {
    if ( 'string' === typeof value ) {
        return value.trim();
    }
    return value;
}

configure({
    // Generates an English message locale generator
    generateMessage: localize('zh-tw', {
        messages: {
            confirmed: '輸入的密碼不相符!',
            required: '必填'
        },
    }),
});

setLocale('zh-tw');

// 官方堤共的 rule
defineRule('confirmed', confirmed);

// 必填
defineRule("required", required);

// 驗證手機格式
defineRule("accountLast5num", (value) => {
    var value = trimValue(value);
    if (/[0-9]{5}/.test(value) || isNullOrUndefinedOrEmpty(value) ) return true;
    return "限輸入5碼數字";
});

// 驗證密碼
defineRule("verifyPassword", (value) => {
    var value = trimValue(value);
    if (store.state.verifyPatterns.common.test(value) || isNullOrUndefinedOrEmpty(value)) return true;

    return store.state.commonErrorMsg;
});

// 驗證手機格式
defineRule("verifyMobile", (value) => {
    var value = trimValue(value);
    if (/^[09]{2}[0-9]{8}$/.test(value) || isNullOrUndefinedOrEmpty(value)) return true;

    return "需為數字及開頭為09、含10個字元";
});

// 驗證驗證碼
defineRule("verifyIdentify", (value, [identifyVal]) => {
    var value = trimValue(value);
    var identifyVal = trimValue(identifyVal)
    if (value === identifyVal) return true;

    return "驗證碼錯誤";
});

// 驗證密碼一致性
defineRule("confirmedPwd", (value, [compareVal]) => {
    var value = trimValue(value);
    var compareVal = trimValue(compareVal);
    if (value !== compareVal) {
        return "密碼不一致，請重新輸入";
    }

    return true;
});

// 驗證信箱
defineRule("verifyEmail", (value) => {
    var value = trimValue(value);
    if (store.state.verifyPatterns.email.test(value) || isNullOrUndefinedOrEmpty(value)) return true;

    return '請輸入正確的信箱格式';
});

// 身分證字號
defineRule("verifyIdNum", (value) => {
    var value = trimValue(value);
    if ( /^[A-Za-z][12]\d{8}$/.test(value) || isNullOrUndefinedOrEmpty(value)) return true;

    return '請輸入正確的身份證字號格式';
});

// 統一編號
defineRule("verifyInvoice", (value) => {
    var value = trimValue(value);
    if (isNullOrUndefinedOrEmpty(value)) return;

    var errMsg = '統一編號錯誤';

    var cx = new Array;
    cx[0] = 1;
    cx[1] = 2;
    cx[2] = 1;
    cx[3] = 2;
    cx[4] = 1;
    cx[5] = 2;
    cx[6] = 4;
    cx[7] = 1;

    var invoice_number = value;
    var sum = 0;
    if (invoice_number.length != 8) {
        return errMsg;
    }
    var num = 0;
    var cnum = invoice_number.split("");
    for (var i=0; i<=7; i++) {
        if (invoice_number.charCodeAt() < 48 || invoice_number.charCodeAt() > 57) {
            return errMsg;
        }

        num = parseInt(cnum[i] * cx[i]);
        if (num > 9) {
            var s = num + "";
            var n1 = parseInt(s.substring(0,1) * 1);
            var n2 = parseInt(s.substring(1,2) * 1);
            num = n1 + n2;
        }
        sum += num;
    }
    if (sum % 10 == 0) return true;
    else if (cnum[6] == 7 && (sum + 1) % 10 == 0) return true;
    else  return errMsg;
});
