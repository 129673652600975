import { baseGet, basePost } from "@/js/services/baseService";
import { f7 } from "framework7-vue";
import { computed, reactive } from "vue";
import store from "../store";

export default {
    namespaced: true,
    state: () => ({
        // 目前儲值金
        money_format: 0,
        // 最少購買數量
        amount: 7, 
        // 贈品
        freebie:{
            // 每購買num個就贈送贈品
            num: null,
            id: null,
            spec_id: null,
            pic_src: null,
            stock: null,
        },
        // 免運門檻
        free_delivery_threshold: 0,
        products:[
            // 格式 :
            // 在api送出時，也把贈品放進 products array一起送出
            // {
            //     id: null,
            //     name: null,
            //     // 購買數量
            //     orderNum: 1,
            //     ori_price: 1280,
            //     ori_price_format: null,
            //     pic_src: null,
            //     product_type: null,
            //     spec_id: null,
            //     stock: null
            // }
        ],
    }),
    getters: {
        // 產品資料
        currentCartData(state, getters){
            return computed( () => {
                let products = !getters.totalOrderFreebies.value ? state.products : [
                    // 贈品
                    {
                        id: state.freebie.id,
                        spec_id: state.freebie.spec_id,
                        orderNum: getters.totalOrderFreebies.value,
                    }, ...state.products];

                return {
                    // 目前儲值金
                    money_format: state.money_format || 0,
                    // 最少購買數量
                    amount: state.amount,
                    freebie: state.freebie,
                    // products array 不含 freebie
                    productsNoFreebie: state.products,
                    // producst array
                    products: products,
                    // 免運門檻
                    free_delivery_threshold: state.free_delivery_threshold || 0
                }
            })
        },
        // 當前購物車購買的產品數量
        totalOrderNum(state) {
            return computed(() => {
                var result = 0;
                state.products.forEach((item, idx) => {
                    if ( item.orderNum ) {
                        result += (item.orderNum ? parseInt(item.orderNum) : 0);
                    }
                });
                return result;
            })
        },
        // 當前購物車可獲得的 贈品數量
        totalOrderFreebies(state, getters) {
            return computed(() => {
                var result = 0;

                if(!state.freebie) return result;
                if(!state.freebie.num) return result;

                var calc = Math.floor( getters.totalOrderNum.value / state.freebie.num );
                if ( calc ) {
                    result = calc
                }
                return result;
            })
        },
        // 當前購物車應付金額
        totalOrderPayment(state) {
            return computed(() => {
                var result = 0;
                state.products.forEach((item, idx) => {
                    result += item.ori_price * item.orderNum;
                });

                return result;
            })
        },
        // 是否已達到最低購買數量門檻
        reachMiniumOrderNum(state, getters) {
            return getters.totalOrderNum.value >= state.amount;
        }
    },
    actions: {
        // 初始化 cart
        getLatest({ commit }, dataObj) {
            const authInfo = store.getters['auth/authInfo'];
            return baseGet('/products?' + f7.utils.serializeObject({
                member_id: authInfo.member_id,
                mobile: authInfo.mobile,
                token: authInfo.token
            }), {}).then(res => {
                const { data, status } = res;
                const resData = data['res_data']
                if (status === 200 && data["res_code"] === 1) {
                    commit('updateCartData', resData)
                } else {
                    console.log(data["res_content"], "訊息提示");
                }
            });
        },
        // 檢查庫存是否足夠
        checkSelectedProductStock({ commit , state }, dataObj) {
            const authInfo = store.getters['auth/authInfo'];
            var pass = true;
            var stockFailArr = []

            return new Promise((resolve, reject)=>{
                baseGet('/products?' + f7.utils.serializeObject({
                    member_id: authInfo.member_id,
                    mobile: authInfo.mobile,
                    token: authInfo.token
                }), {}).then(res => {
                    const { data, status } = res;
                    const resData = data['res_data']
                    if (status === 200 && data["res_code"] === 1) {

                        var products = resData.products;
    
                        // 有已選擇的資料
                        if ( state.products ) {
                            // 檢查當前輸入的數量是否超過庫存
                            state.products.forEach(selectedProduct => {
                                products.forEach(product =>{
                                    if ( selectedProduct.id == product.id ) {
                                        if ( selectedProduct.orderNum ){
                                            // 有輸入數量
                                            if ( selectedProduct.orderNum > product.stock ){
                                                // 超過庫存
                                                product.orderNum = product.stock;
                                                stockFailArr.push(selectedProduct)

                                                pass = false;
                                            } else {
                                                product.orderNum = selectedProduct.orderNum
                                            }
                                        } else {
                                            product.orderNum = 0;
                                        }
                                    }
                                })
                            })
                        }

                        // 將列表更新為最新的資料
                        commit('updateCartData', resData)
                    } else {
                        console.log(data["res_content"], "訊息提示");
                    }
                }).finally(()=>{
                    resolve({
                        pass,
                        stockFailArr
                    })
                })
            });

        },
    },
    mutations: {
        // 更新購物車資料
        updateCartData(state, dataObj) {
            // 儲值金
            if(dataObj.money_format !== undefined) {
                state.money_format = dataObj.money_format;
            }
            // 最少購買數量
            if(dataObj.amount !== undefined || dataObj.buy_num !== undefined) {
                state.amount = dataObj.amount || dataObj.buy_num || '';
            }
            // freebie
            if ( dataObj.freebie !== undefined) {
                if(dataObj.freebie.num !== undefined) {
                    state.freebie.num = dataObj.freebie.num;
                }
                if(dataObj.freebie.id !== undefined) {
                    state.freebie.id = dataObj.freebie.id;
                }
                if(dataObj.freebie.spec_id !== undefined) {
                    state.freebie.spec_id = dataObj.freebie.spec_id;
                }
                if(dataObj.freebie.name !== undefined) {
                    state.freebie.name = dataObj.freebie.name;
                }
                if(dataObj.freebie.pic_src !== undefined) {
                    state.freebie.pic_src = dataObj.freebie.pic_src;
                }
                if(dataObj.freebie.stock !== undefined) {
                    state.freebie.stock = dataObj.freebie.stock;
                }
            }

            if(dataObj.products !== undefined) {
                // products
                dataObj.products.forEach((item , idx) => {
                    if (!item.orderNum) item.orderNum = 0;
                });

                state.products = dataObj.products;
            }

            if(dataObj.free_delivery_threshold !== undefined) {
                state.free_delivery_threshold = dataObj.free_delivery_threshold
            }
        },
        // 更新目標產品的購買數量
        updateProductNum(state, data) {
            var id = data.id;
            var product;

            state.products.some((val, idx) => {
                if ( val.id == id) {
                    product = val;
                    return;
                }
            });
            
            product.orderNum = data.orderNum;
        },
        /**
         * 重設cart內產品的購買數量
         * @param {*} newOrderNum 
         */
        resetAllProductOrderNum(state, data){
            var newOrderNum = (data && data.newOrderNum) || 0;
            state.products.forEach((product, idx) => {
                product.orderNum = newOrderNum;
            });
        },
        updateAmt(state, num){
            state.freebie.num = num
        },
    },
}

