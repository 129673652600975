
// import { createStore } from 'framework7/lite';
import { f7 } from "framework7-vue";
import { baseGet, basePost } from "@/js/services/baseService";
import { createStore, createLogger } from 'vuex'
import modules from './modules' // 引入modules

const store = createStore({
  modules, // 放到這裡
  state() {
    return {
      // f7 view 準備就緒
      isViewReady: false,
      // 未讀訊息
      msgUnread: null,
      // APP審核用帳號
      appStoreReviewAccount: false,
      products: [
      ]
    }
  },
  getters: {
    
  },
  actions: {
    // 取得未讀訊息數量
    async getMsgUnread({ commit }, data){
      let authInfo = store.getters['auth/authInfo'];
      let result = 0;

      await baseGet(`/notifications/num?` + f7.utils.serializeObject({
        mobile: authInfo.mobile,
        token: authInfo.token
      }), {})
      .then(res => {
        const { data, status } = res;
        const resData = data['res_data'];

        if (status === 200 && data["res_code"] === 1) {
          commit('updateMsgUnread', resData.num)
          result =  resData.num;
        } else {
          console.log('getMsgUnread error', data["res_code"])
        }
      }).catch(err=>{
        console.log('getMsgUnread error', err)
      })

      return result;
    }
  },
  mutations: {
    // view 已準備就緒
    updateCommon(state, data) {
      for(let key in data){
        if ( state.hasOwnProperty(key) && data[key] !== undefined ) {
          state[key] = data[key]
        }
      }
    },
    // 更新未讀訊息數量
    updateMsgUnread(state, num){
      if ( !num ) throw '請傳入數值'
      state.msgUnread = num;
    },
    // view 已準備就緒
    setViewReady(state) {
      state.isViewReady = true
    }
  },
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})
export default store;
