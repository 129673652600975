import { onMounted, watch, toRef } from "vue";
import { RandomFunc } from "@/js/functions.js";
export default {
  props: {
    identifyCode: {
      type: String,
      default: "1234"
    },
    fontSizeMin: {
      type: Number,
      default: 16
    },
    fontSizeMax: {
      type: Number,
      default: 40
    },
    backgroundColorMin: {
      type: Number,
      default: 180
    },
    backgroundColorMax: {
      type: Number,
      default: 240
    },
    colorMin: {
      type: Number,
      default: 40
    },
    colorMax: {
      type: Number,
      default: 180
    },
    lineColorMin: {
      type: Number,
      default: 40
    },
    lineColorMax: {
      type: Number,
      default: 180
    },
    dotColorMin: {
      type: Number,
      default: 0
    },
    dotColorMax: {
      type: Number,
      default: 255
    },
    contentWidth: {
      type: Number,
      default: 112
    },
    contentHeight: {
      type: Number,
      default: 38
    }
  },
  setup: function setup(props) {
    var fontSizeMin = props.fontSizeMin,
        fontSizeMax = props.fontSizeMax,
        backgroundColorMin = props.backgroundColorMin,
        backgroundColorMax = props.backgroundColorMax,
        colorMin = props.colorMin,
        colorMax = props.colorMax,
        lineColorMin = props.lineColorMin,
        lineColorMax = props.lineColorMax,
        dotColorMin = props.dotColorMin,
        dotColorMax = props.dotColorMax,
        contentWidth = props.contentWidth,
        contentHeight = props.contentHeight;
    var identifyCode = toRef(props, 'identifyCode');

    function drawPic() {
      var canvas = document.getElementById("identify-render");
      var ctx = canvas.getContext("2d");
      ctx.textBaseLine = "bottom"; // 繪製背景

      ctx.fillStyle = RandomFunc.randomColor(backgroundColorMin, backgroundColorMax);
      ctx.fillRect(0, 0, contentWidth, contentHeight); // 繪製文字

      for (var i = 0; i < identifyCode.value.length; i++) {
        drawText(ctx, identifyCode.value[i], i);
      }

      drawLine(ctx);
      drawDot(ctx);
    }

    function drawText(ctx, text, idx) {
      ctx.fillStyle = RandomFunc.randomColor(colorMin, colorMax);
      ctx.font = RandomFunc.randomNum(fontSizeMin, fontSizeMax) + "px SimHei";
      var x = (idx + 1) * (contentWidth / (identifyCode.value.length + 1));
      var y = RandomFunc.randomNum(fontSizeMax, contentHeight - 5);
      var deg = RandomFunc.randomNum(-45, 45); // 修改座標圓點和選轉角度

      ctx.translate(x, y);
      ctx.rotate(deg * Math.PI / 180);
      ctx.fillText(text, 0, 0); // 恢復座標圓點和選轉角度

      ctx.rotate(-deg * Math.PI / 180);
      ctx.translate(-x, -y);
    }

    function drawLine(ctx) {
      // 繪製干擾線
      for (var i = 0; i < 9; i++) {
        ctx.strokeStyle = RandomFunc.randomColor(lineColorMin, lineColorMax);
        ctx.beginPath();
        ctx.moveTo(RandomFunc.randomNum(0, contentWidth), RandomFunc.randomNum(0, contentHeight));
        ctx.lineTo(RandomFunc.randomNum(0, contentWidth), RandomFunc.randomNum(0, contentHeight));
        ctx.stroke;
      }
    }

    function drawDot(ctx) {
      // 繪製干擾點
      for (var i = 0; i < 100; i++) {
        ctx.fillStyle = RandomFunc.randomColor(0, 255);
        ctx.beginPath();
        ctx.arc(RandomFunc.randomNum(0, contentWidth), RandomFunc.randomNum(0, contentHeight), 1, 0, 2 * Math.PI);
        ctx.fill();
      }
    }

    watch(identifyCode, function (currentCode, prevCode) {
      drawPic();
    });
    onMounted(function () {
      drawPic();
    });
  }
};