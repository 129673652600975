import { f7, f7ready } from 'framework7-vue';
import { ref, onMounted, computed, watch } from 'vue';
import { baseGet, postLogin } from "@/js/services/baseService";
import routes from '../js/routes.js';
import { useStore } from 'vuex';
export default {
  setup: function setup(props) {
    // store
    var store = useStore();
    window.store = store; // isViewReady 狀態

    var isViewReady = computed(function () {
      return store.state.isViewReady;
    });
    watch(function () {
      return isViewReady.value;
    }, function (now, prev) {
      if (now) {
        $('html').addClass('is-view-ready');
      }
    }); // 初始化 auth

    store.dispatch('auth/init');
    var isLogined = store.getters['auth/isLogined'] || null; // loader

    window.mukiLoader = function () {
      var timeout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1000;
      // 超過一秒則租出現oader
      timeout = setTimeout(function () {
        f7.preloader.show();
      }, timeout);
      return {
        cancel: function cancel() {
          clearTimeout(timeout);
        },
        hide: function hide() {
          clearTimeout(timeout);
          f7.preloader.hide();
        }
      };
    };

    window.mukiURLSearchParams = function () {
      var browserHistoryEnable = f7.params.view.browserHistory;
      var searchParams = '';

      if (browserHistoryEnable) {
        searchParams = location.hash.split('?') ? location.hash.split('?')[1] : '';
      } else {
        searchParams = window.location.search;
      }

      var urlSearchParams = new URLSearchParams(searchParams);
      var params = Object.fromEntries(urlSearchParams.entries());
      return params;
    };

    window.getPage = function () {
      return $(f7.view.main.router.currentPageEl);
    };

    window.getURL = function (path) {
      if (process.env.NODE_ENV == 'development') {
        return path;
      } else {
        return '/mobile' + path;
      }
    }; // f7 view 準備就緒


    window.setViewReady = function () {
      store.commit('setViewReady');
    }; // 複寫舊模組路徑函數


    window.directURL = _direct_url = function _direct_url(url) {
      var final = getURL(url);
      window.location.href = final;
    }; // view switch


    var currentViewArr = [];

    var getPathName = function getPathName() {
      var replaceTxt = location.protocol + '//' + location.hostname + '/mobile';
      var href = location.href ? location.href.replace(replaceTxt, '') : ''; // 取代掉 http://xxx/mobile

      var pathName = location.pathname ? location.pathname.replace('/mobile', '') : ''; // 取代掉 /mobile

      var hrefViewName = href.indexOf('/#!/') > -1 ? href.split('/#!/')[1] : "";
      var pathViewName = pathName.split('/').length > 1 ? pathName.split('/')[1] : "";
      var finalName = hrefViewName || pathViewName;

      if ((pathName == '/' || pathName == '') && location.hash == '') {
        finalName = '/';
      }

      return finalName;
    };

    var viewSwitcher = function viewSwitcher(viewName) {
      var finalName = getPathName(); // 紀錄已經處理過的view

      currentViewArr.push(viewName);
      var result = false;

      if (finalName) {
        result = finalName == viewName;
      }

      var backFromPaymentParams = f7.utils.parseUrlQuery(location.search); // 從金流頁返回- 幫使用者自動設為登入狀態

      if (backFromPaymentParams.phone && finalName == 'index') {
        var postData = {
          mobile: backFromPaymentParams.phone,
          // 宇皓: 金流頁返回帶入參數為1
          cash_success: 1
        };
        result = true;
        postLogin('/login', postData).then(function (res) {
          var data = res.data,
              status = res.status;

          if (status === 200 && data["res_code"] === 1) {
            // 自動登入成功
            _direct_url('/index');
          } else {
            // 自動登入失敗
            _direct_url('/');
          }
        }).catch(function () {
          // 自動登入失敗
          _direct_url('/');
        });
      } else {
        // 首頁登入驗證
        // isLogined snapshot
        // dont make it reactive
        if (finalName == 'index' && !isLogined) {
          result = false;

          _direct_url('/');
        } // 已登入自動導首頁


        if (finalName == '/' && isLogined) {
          result = false;

          _direct_url('/index');
        }
      }

      return result;
    }; // 是否為獨立框架頁


    var standAloneViewDetech = function standAloneViewDetech(viewName) {
      var finalName = getPathName();
      var isThisView = true;
      var replaceTxt1 = location.protocol + '//' + location.hostname + '/mobile';
      var replaceTxt2 = location.protocol + '//' + location.hostname;
      var f7path1 = location.href.replace(replaceTxt1, '');
      var f7path2 = location.href.replace(replaceTxt2, '');
      var f7path = f7path1.indexOf('mobile/') == 1 ? f7path2 : f7path1;
      currentViewArr.forEach(function (viewName) {
        if (finalName.indexOf(viewName) > -1) {
          isThisView = false;
        }
      }); // console.log('f7 path==', f7path)

      return {
        isThisView: isThisView,
        // f7path 如果是使用此動態產生的，
        // 目前無法正確載入網頁，須直接寫死在view的url
        f7path: String(f7path)
      };
    };

    var toolbarMenuActiveName = ref('/index'); // ==== 模組 ====
    // 滑動偵測

    var ScrollDetect = function ScrollDetect() {
      $$(document).on('page:afterin', function (e, page) {
        $('.view-main .page-content').on('scroll', function (e) {
          if (e.target.scrollTop > 20) {
            $('body').addClass('is-scrolling');
          } else {
            $('body').removeClass('is-scrolling');
          }
        });
      });
      $('.view-main .page-content').on('scroll', function (e, page) {
        $('.view-main .page-content').on('scroll', function (e) {
          if (e.target.scrollTop > 20) {
            $('body').addClass('is-scrolling');
          } else {
            $('body').removeClass('is-scrolling');
          }
        });
      });
    }; // Framework7 Parameters


    var f7params = {
      name: 'beautyagent',
      // App name
      // theme: 'auto', // Automatic theme detection
      // 開發時啟用 pushstate
      view: {// 打開 broweerHistory 在需要back page時可能會有問題
        // 故僅用於開發
        // browserHistory: process.env.NODE_ENV === 'development' ? true:false,
        // It could be useful, for example, 
        // if you have some Form from 5 steps (5 pages) and 
        // when you are on last 5th page you need access to form that was on 1st page.
        // stackPages: true,
      },
      // App store
      store: store,
      // App routes
      routes: routes,
      dialog: {
        buttonOk: '確定',
        buttonCancel: '取消'
      },
      picker: {
        renderToolbar: function renderToolbar() {
          return '<div class="toolbar">' + '<div class="toolbar-inner">' + '<div class="left">' + '</div>' + '<div class="right">' + '<a href="#" class="link sheet-close popover-close reset">確定</a>' + '</div>' + '</div>' + '</div>';
        }
      }
    }; // Login screen data

    var username = ref('');
    var password = ref('');

    var alertLoginData = function alertLoginData() {
      f7.dialog.alert('Username: ' + username.value + '<br>Password: ' + password.value, function () {
        f7.loginScreen.close();
      });
    }; // 自動計算基礎px


    var computeRatioOfBaseSize = function computeRatioOfBaseSize() {
      var initSize = 14;
      var htmlEl = document.querySelector("html");
      var appView = document.querySelector("#app");
      var windowWidth = appView.clientWidth > 768 ? 768 : appView.clientWidth;
      var baseSize = Number(windowWidth) / 375 * initSize;
      if (baseSize > 16) baseSize = 16;
      if (baseSize < 14) baseSize = 14;
      document.documentElement.style.setProperty('--f7-font-size', baseSize + 'px');
      htmlEl.style.fontSize = baseSize + "px";
    }; // segmented tab


    var SegmentedTab = {
      setActive: function setActive(tabLink) {
        // f7 內建 不會移動 tab inner，導致選到的 tab link有可能會部分被擋住，故採用自行移動方式
        var that = this;
        var tabLink = $(tabLink);
        if (!tabLink.length) return;
        var toolbarInner = tabLink.closest('.toolbar-inner');
        var toolbarInnerW = toolbarInner[0].clientWidth;
        var currentScrollLeft = toolbarInner[0].scrollLeft;
        var tabLinkPos = tabLink.position();
        var diff = tabLink[0].offsetLeft + tabLink[0].clientWidth - (currentScrollLeft + toolbarInnerW);
        var target = currentScrollLeft;

        if (tabLinkPos.left < 0) {
          // btn 太左邊
          target = currentScrollLeft + tabLinkPos.left;
        } else if (diff > 0) {
          // btn 太右邊
          target = currentScrollLeft + diff;
        }

        toolbarInner.animate({
          scrollLeft: target
        }, 300);
        that.updateSegmented(tabLink);
      },
      updateSegmented: function updateSegmented(tabLink) {
        // f7 內建 移動 segement 的位置會有誤差，故改採自行修改位置的方式
        var tabLink = $(tabLink);
        if (!tabLink.length) return;
        var toolbarInner = tabLink.closest('.toolbar-inner');
        var tabLinkW = tabLink.outerWidth();
        var segEl = toolbarInner.find('.tab-link-bg-hilighter');
        segEl.css('width', tabLinkW);
        segEl.css('left', tabLink.position().left + toolbarInner[0].scrollLeft);
      },
      init: function init() {
        var that = this;
        $('body').on('click', '.tab-link', function (e) {
          that.setActive(e.currentTarget);
        });
        $$(document).on('page:beforein', function (e, page) {
          that.updateSegmented($(page.$el).find('.tabbar-scrollable .tab-link-active'));
        });
      }
    };
    onMounted(function () {
      computeRatioOfBaseSize();
      f7ready(function () {
        var ToolbarMenu = {
          init: function init() {
            var toolbarMenu = $('.toolbar-menu');
          }
        };
        ScrollDetect();
        SegmentedTab.init(); // ==== PAGE BEFORE IN 頁面事件 ====

        $$(document).on('page:beforein', function (e, page) {
          ToolbarMenu.init();

          if (f7.view.current) {
            var toolbarPath = f7.view.current.router.currentRoute.path; // 我的帳戶

            if (toolbarPath.indexOf('/my_account') > -1) toolbarPath = '/my_account';
            toolbarMenuActiveName.value = toolbarPath;
          }
        });
        $$(document).on('page:afterin', function (e, page) {});
        $$(document).on('page:beforeout', function (e, page) {}); // Call F7 APIs here
      });
    });
    return {
      mukiConst: mukiConst,
      store: store,
      location: location,
      refreshPage: refreshPage,
      f7params: f7params,
      username: username,
      password: password,
      alertLoginData: alertLoginData,
      viewSwitcher: viewSwitcher,
      standAloneViewDetech: standAloneViewDetech,
      toolbarMenuActiveName: toolbarMenuActiveName
    };
  }
};