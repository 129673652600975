import { f7 } from "framework7-vue";
import { ref, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useForm } from "vee-validate";
import { postLogin } from "@/js/services/baseService";
import { RandomFunc } from "@/js/functions.js";
export default {
  components: {},
  props: {
    f7router: Object
  },
  setup: function setup(props) {
    // 給註冊頁面用
    // f7.view.main.params.stackPages = true;
    var f7router = props.f7router;
    var randomNum = RandomFunc.randomNum; // store

    var store = useStore(); // 記錄嘗試登入次數

    var tryLoginNum = ref(0); // btn

    var loginBtnDisable = ref(false); // 驗證碼info

    var identifyInfo = reactive({
      codes: "1234567890",
      code: ""
    }); // form驗證設定

    var _useForm = useForm({
      validateOnMount: false,
      validationSchema: {
        account: "required",
        pwd: "required"
      },
      initialValues: {
        mobile: '',
        captcha: ''
      }
    }),
        errors = _useForm.errors,
        isSubmitting = _useForm.isSubmitting,
        submitCount = _useForm.submitCount,
        meta = _useForm.meta,
        values = _useForm.values,
        setFieldError = _useForm.setFieldError,
        setErrors = _useForm.setErrors,
        setFieldValue = _useForm.setFieldValue,
        setValues = _useForm.setValues,
        validate = _useForm.validate,
        validateField = _useForm.validateField,
        handleSubmit = _useForm.handleSubmit,
        submitForm = _useForm.submitForm,
        handleReset = _useForm.handleReset,
        resetForm = _useForm.resetForm; // 驗證帳號


    var onFormSubmit = handleSubmit(function (values, actions) {
      // return false;
      var account = values.account,
          pwd = values.pwd;
      var postData = {
        mobile: account,
        password: pwd
      };
      loginBtnDisable.value = true;
      postLogin('/login', postData).then(function (res) {
        var data = res.data,
            status = res.status;

        if (status === 200 && data["res_code"] === 1) {
          f7.dialog.alert(data["res_content"], "訊息提示", function () {
            _direct_url('/index');
          });
        }
      }).finally(function () {
        loginBtnDisable.value = false;
      });
    }); // 生成驗證碼

    function generateCode(codeInfoObj, digit) {
      for (var i = 0; i < digit; i++) {
        codeInfoObj.code += codeInfoObj.codes[randomNum(0, codeInfoObj.codes.length)];
      }
    } // 重新生成驗證碼


    function refreshCode() {
      identifyInfo.code = "";
      generateCode(identifyInfo, 4);
    }

    onMounted(function () {});
    setViewReady();
    return {
      onFormSubmit: onFormSubmit,
      refreshCode: refreshCode,
      tryLoginNum: tryLoginNum,
      identifyInfo: identifyInfo,
      loginBtnDisable: loginBtnDisable,
      values: values
    };
  }
};